import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { connect } from "react-redux";
import { load_user } from "../actions/auth";
import { Paper } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { theme } from "./ThemeConfig";

const Layout = ({ load_user, children }) => {
  const location = useLocation().pathname;
  const [show_footer, set_show_footer] = useState();
  
  useEffect(() => {
    load_user();
  }, []);

  useEffect(() => {
    if (location.includes("dashboard")){
      set_show_footer(false)
    }else{

      set_show_footer(true)
    }
  }, [location])
  

  return (
    <ThemeProvider theme={theme}>

      <Paper sx={{bgcolor: "#F9F9F9", boxShadow:0, }} >
        <Navbar />
        {children}
        {show_footer ? 
        <Footer />
        :""}
      </Paper>
    </ThemeProvider>
  );
};

export default connect(null, { load_user })(Layout);
