
import { Alert, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Tooltip, Typography, outlinedInputClasses } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { axios_get_call, axios_post_call } from '../../components/AxiosCall';
import ConfettiExplosion from 'react-confetti-explosion';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import generate_local_timezone_based_iso_date from '../../components/generate_local_timezone_based_iso_date';

function Raffle({ user }) {
    const navigate = useNavigate();
    const [loading, set_loading] = useState(false);
    const [alert, set_alert] = useState(false);
    const [events, set_events] = useState([]);
    const [event_name, set_event_name] = useState("");
    const [no_of_winners, set_no_of_winners] = useState(1);
    const [output_type, set_output_type] = useState("Digital");
    const [winners, set_winners] = useState([]);
    const [winners_to_print, set_winners_to_print] = useState([]);
    const [pick_a_winner_confirmation_dialog, set_pick_a_winner_confirmation_dialog] = useState(false);

    const [start_date, set_start_date] = React.useState(dayjs().subtract(30, 'day'));
    const [end_date, set_end_date] = React.useState(dayjs(new Date().date));
    const get_events = async () => {
        let url = "/lead-generation/get-events/";

        let res = await axios_get_call(
            url,
            {

            },
            set_loading
        );

        if (res.data.status == 1) {
            set_loading(false);
            set_events(res.data.response);

        } else if (res.data.status == 0) {
            set_loading(false);
            set_alert(res.data.response);
        }
    }


    const get_month_name = (date) => {
        const month = date.toLocaleString('default', { month: 'long' });
        return month
    }


    useEffect(() => {
        get_events();
    }, []);

    useEffect(() => {
        if (user)
            if (user.location_name == null) navigate("/complete-profile");
            else if (user.name == null) navigate("/complete-profile");

            else if (user.location_phone == null) navigate("/complete-profile");
            else if (user.location_page_url == null) navigate("/complete-profile");
            else if (user.location_address == null) navigate("/complete-profile");
            else if (user.location_city_or_state == null)
                navigate("/complete-profile");


    }, [])


    const renderTable = () => {
        const numColumns = 3;
        const numRows = Math.ceil(winners_to_print.length / numColumns);

        const tableRows = [];
        let nameIndex = 0;

        for (let i = 0; i < numRows; i++) {
            const tableColumns = [];

            for (let j = 0; j < numColumns; j++) {
                if (nameIndex < winners_to_print.length) {
                    tableColumns.push(<td style={{ border: '1px dashed lightgrey' }} key={nameIndex}>
                        <Typography variant="subtitle2" align='center' sx={{ whiteSpace: "nowrap", fontSize: '18px', }}>

                            {winners_to_print[nameIndex]}
                        </Typography>

                    </td>);
                    nameIndex++;
                } else {
                    tableColumns.push(<td style={{ padding: "5px", border: '1px solid lightgrey' }} key={`empty-${j}`}>&nbsp;</td>);
                }
            }

            tableRows.push(<tr key={i}>{tableColumns}</tr>);
        }

        return tableRows;
    };

    const get_winners = async () => {
        let url = "/lead-generation/get-winners/";
        set_alert(false)

        const parsed_start_date = generate_local_timezone_based_iso_date(new Date(start_date), true)
        const parsed_end_date = generate_local_timezone_based_iso_date(new Date(end_date), true)

        let res = await axios_post_call(
            url,
            {
                event_name, no_of_winners, output_type, start_date: parsed_start_date, end_date: parsed_end_date
            },
            set_loading
        );

        if (res.data.status == 1) {
            set_loading(false);
            if (output_type == "Digital")
                set_winners(res.data.response)
            else
                set_winners_to_print(res.data.response);

        } else if (res.data.status == 0) {
            set_loading(false);
            set_alert(res.data.response);
        }
    }

    const print_names = () => {
        let printContents = document.getElementById('names_container');
        const printWindow = window.open('', '_blank');
        printWindow.document.open();
        printWindow.document.write(`
      <html>
      <head>
    
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin="">
      <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&amp;display=swap" rel="stylesheet">
    
      <link href="https://fonts.googleapis.com/css?family=Heebo:400,700|IBM+Plex+Sans:600" rel="stylesheet">
      <title>100% Marketing Platform</title>
      <script src="https://unpkg.com/scrollreveal@4.0.0/dist/scrollreveal.min.js"></script>
   
  
<style>
h6{
margin: 0px;
font-family: avenir roman,sans-serif;
font-weight: 400;
line-height: 1.5;
letter-spacing: 0.00938em;
color: gray;
text-align: center;
white-space: nowrap;
font-size: 18px;
}
</style>

</head>
        <body>
          ${printContents.innerHTML}
        </body>
      </html>
    `);
        printWindow.document.close();
        printWindow.print();
        // printWindow.close();


        // let originalContents = document.body.innerHTML;
        // document.body.innerHTML = printContents;
        // window.print();
        // document.body.innerHTML = originalContents;
    }
    return (
        user ?

            <Box>

                {pick_a_winner_confirmation_dialog ?

                    <Box
                        sx={{
                            "-webkit-text-size-adjust": "100%",
                            "-webkit-tap-highlight-color": "transparent",
                            "box-sizing": "border-box",
                            minHeight: "100vh",
                            top: "0",
                            right: "0",
                            bottom: "0",
                            left: "0",
                            position: "fixed",
                            "z-index": "1050",
                            display: "flex",
                            outline: "0",
                            "text-align": "center",
                            padding: "0!important",
                            justifyContent: "center",
                            alignItems: "center",
                            bgcolor: "rgba(0,0,0,0.4)",
                            "backdrop-filter": "blur(10px)",
                            "overflow-x": "hidden",
                            "overflow-y": "auto!important",
                            "&:hover": { cursor: "pointer" },
                        }}
                    >

                        <Box
                            id="CustomModal2"
                            py={4}
                            sx={{
                                zIndex: 123214,
                                margin: "auto",
                                width: 1,
                                minHeight: 1,
                                cursor: "not-allowed",

                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <style
                                dangerouslySetInnerHTML={{
                                    __html:
                                        "\nbody{\n    overflow: hidden !important;\npadding-right: 15px !important;\n}\n",
                                }}
                            />
                            <Box
                                sx={{
                                    width: {
                                        xl: "27%",
                                        lg: "35%",
                                        md: "40%",
                                        sm: "50%",
                                        xs: "100%",
                                    },
                                    h: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    bgcolor: "white",
                                    cursor: "auto",
                                    borderRadius: 3,
                                    boxShadow: 24,
                                    m: 2,
                                    p: 4
                                }}
                            >
                                <Box sx={{ m: 1 }}>
                                    <Typography mt={2} variant="h2">
                                        Are you sure you want to pick a winner now?
                                    </Typography>
                                    <Typography mb={2} variant="subtitle2">
                                        Clicking this button will trigger the Raffle selection and confetti animation
                                    </Typography>

                                    <Box flex={true}>
                                        <Button variant='outlined' onClick={() => { set_pick_a_winner_confirmation_dialog(false); }}>
                                            Cancel
                                        </Button>
                                        <Button variant='contained' onClick={() => { set_pick_a_winner_confirmation_dialog(false); get_winners() }}>
                                            Yes
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    : ""}

                {winners.length > 0 ?

                    <Box
                        sx={{
                            "-webkit-text-size-adjust": "100%",
                            "-webkit-tap-highlight-color": "transparent",
                            "box-sizing": "border-box",
                            minHeight: "100vh",
                            top: "0",
                            right: "0",
                            bottom: "0",
                            left: "0",
                            position: "fixed",
                            "z-index": "1050",
                            display: "flex",
                            outline: "0",
                            "text-align": "center",
                            padding: "0!important",
                            justifyContent: "center",
                            alignItems: "center",
                            bgcolor: "rgba(0,0,0,0.4)",
                            "backdrop-filter": "blur(10px)",
                            "overflow-x": "hidden",
                            "overflow-y": "auto!important",
                            "&:hover": { cursor: "pointer" },
                        }}
                    >

                        <Box
                            id="CustomModal"
                            py={4}
                            sx={{
                                zIndex: 123214,
                                margin: "auto",
                                width: 1,
                                minHeight: 1,
                                cursor: "not-allowed",

                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <style
                                dangerouslySetInnerHTML={{
                                    __html:
                                        "\nbody{\n    overflow: hidden !important;\npadding-right: 15px !important;\n}\n",
                                }}
                            />
                            <Box
                                sx={{
                                    width: {
                                        xl: "27%",
                                        lg: "35%",
                                        md: "40%",
                                        sm: "50%",
                                        xs: "100%",
                                    },
                                    h: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    bgcolor: "white",
                                    cursor: "auto",
                                    borderRadius: 3,
                                    boxShadow: 24,
                                    m: 2
                                }}
                            >
                                <Box>
                                    <Typography mt={2} variant="h2">
                                        {winners.length > 1 ? "The winners are" : "The winner is"}
                                    </Typography>
                                </Box>
                                <Box>
                                    <ConfettiExplosion id={"explosion_particles"} colors={["#042b48", "#c2e2d9", "#f9b031"]} force={0.7} zIndex={223214} particleCount={250} width={1920} particleSize={15} duration={3000} />
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: 1, m: 2 }}>
                                    {winners.map((winner) => {
                                        return (
                                            <Tooltip placement='top' title={`ID: ${winner.id}, Email: ${winner.email}`}>
                                                <Box sx={{ bgcolor: "customBox.default", py: 2, my: 2, width: 1 }}>
                                                    <Typography variant='h3'>
                                                        {winner.name}
                                                    </Typography>
                                                </Box>
                                            </Tooltip>
                                        )
                                    })}
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                        alignItems: "center",
                                        p: 2,
                                    }}
                                >
                                    <Button
                                        variant="outlined"
                                        fullWidth
                                        sx={{minHeight: '43px',}}
                                        onClick={() => {
                                            set_winners([])
                                        }}
                                    >
                                        Close
                                    </Button>

                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    : ""}
                {winners_to_print.length > 0 && output_type != 'Digital' ?
                    <Box>
                        <Box>
                            <Button variant="outlined" sx={{ ml: 3, minHeight: '43px', }} onClick={() => { set_winners_to_print([]) }}>
                                Cancel
                            </Button>
                            <Tooltip title='You can print or select "save as PDF" option in printer option to save as PDF'>
                                <Button sx={{ minHeight: '43px',}} variant="contained" onClick={() => { print_names() }}>
                                    Print
                                </Button>
                            </Tooltip>
                        </Box>
                        <Box id="names_container">
                            <Typography variant="subtitle2" align='center' sx={{ whiteSpace: "nowrap", fontSize: '20px' }}>

                                Submissions for event:    <strong>{event_name}  </strong>
                            </Typography>
                            <table style={{ width: '100%', marginTop: '10px', background: "white", padding: 10 }}>
                                <tbody>{renderTable()}</tbody>
                            </table>
                        </Box>
                    </Box>
                    :
                    <Box>
                        <Box sx={{ bgcolor: "#ededed", maxWidth: { lg: "40%", md: "60%", sm: "80%", sm: "100%" }, minHeight: "80vh", margin: "auto", display: "flex", flexDirection: "column", mb: 2 }}>

                            <Typography variant="h1" m={4}>

                                Raffle
                            </Typography>

                            <Box sx={{ m: 4 }}>

                                <Box sx={{ margin: "auto", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", maxWidth: { lg: "52%", md: "72%", sm: "92%", sm: "100%" }, }}>
                                    <Box sx={{ flex: 1, mb: 2, width: "-webkit-fill-available" }}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Select an event</InputLabel>
                                            <Select
                                                sx={{ bgcolor: "white" }}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"

                                                onChange={(e) => {

                                                    set_event_name(e.target.value.title);
                                                    set_start_date(dayjs(e.target.value.start_date));
                                                    set_end_date(dayjs(e.target.value.end_date));
                                                }}
                                            >
                                                {events.length > 0 ? events.map((event) => {
                                                    return (
                                                        <MenuItem value={event}>{event.title} ({get_month_name(new Date(event.start_date))} - {get_month_name(new Date(event.end_date))})</MenuItem>
                                                    )
                                                }) : <MenuItem value={{}}>No events found</MenuItem>}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box sx={{ flex: 1, mb: 2, width: "-webkit-fill-available" }}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Output type</InputLabel>
                                            <Select
                                                sx={{ bgcolor: "white" }}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                defaultValue={output_type}
                                                onChange={(e) => {

                                                    set_output_type(e.target.value);

                                                }}
                                            >
                                                {["Print out", "Digital"].map((output) => {
                                                    return (
                                                        <MenuItem value={output}>{output}</MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    {output_type == "Digital" ?
                                        <Box sx={{ flex: 1, mb: 2, width: "-webkit-fill-available" }}>
                                            <TextField
                                                sx={{ bgcolor: "white" }}
                                                fullWidth
                                                label="Select no of winners"
                                                variant="outlined"
                                                id={"no_of_winners"}
                                                name={"no_of_winners"}
                                                value={no_of_winners}
                                                type={"number"}
                                                onWheel={(e) => {
                                                    e.target.blur()
                                                }}
                                                inputMode="numeric"
                                                placeholder={"Select number of winners"}
                                                onChange={(e) => {
                                                    let value = e.target.value;
                                                    if (value > 1) {
                                                        set_no_of_winners(value);
                                                    } else {
                                                        set_no_of_winners(1);
                                                    }

                                                }}
                                            />
                                        </Box> : ""}
                                    {loading ? <Box>
                                        <Button variant="contained" >
                                            <CircularProgress color='secondary' />
                                        </Button>
                                    </Box> : <Box>


                                        {event_name ? output_type == "Digital" ?
                                            <Box>
                                                <Typography variant='subtitle2' align='center'>
                                                    Are you ready to select a winner now?
                                                </Typography>
                                                <Button variant="outlined" fullWidth sx={{ mt: 3, minHeight: '43px'}} onClick={() => { set_pick_a_winner_confirmation_dialog(true) }}>
                                                    Yes, Pick {no_of_winners > 1 ? "winners" : "winner"} now!
                                                </Button>
                                                <Typography variant='subtitle1' align='center'>
                                                    Once you click this button we will have an animation now!
                                                </Typography>
                                            </Box>
                                            :
                                            <Box>
                                                <Button sx={{ minHeight: '43px',}} variant="contained" onClick={() => { get_winners() }}>
                                                    Print list of names
                                                </Button>
                                            </Box>
                                            :
                                            <Box>
                                                <Typography variant='subtitle1' align='center'>
                                                    Please select an event first!
                                                </Typography>
                                            </Box>}
                                    </Box>}
                                    {alert ? (
                                        <Alert
                                            onClose={() => {
                                                set_alert(false);
                                            }}
                                            severity={alert.toLowerCase().includes("[error]") ? "error" : "success"}
                                            sx={{
                                                p: 2,
                                                width: 1,
                                                zIndex: 9923213,
                                                mx: -2,
                                                my: 2,
                                            }}
                                        >
                                            {alert}
                                        </Alert>
                                    ) : (
                                        ""
                                    )}
                                </Box>
                            </Box>

                        </Box>
                    </Box>
                }
            </Box >
            : ""
    )
}

const mapStateToProps = (state) => ({
    state: state,
    user: state.auth.user,
});

export default connect(mapStateToProps, {})(Raffle);