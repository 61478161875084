import React, { useEffect, useRef, useState } from "react";
import { Box, typography } from "@mui/system";
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import { Alert, Table, TableHead, TableBody, TableRow, TableCell, TextField, Button, Divider, FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@mui/material';

import TableContainer from '@mui/material/TableContainer';

import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import * as XLSX from 'xlsx'
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { axios_get_call, axios_post_call } from "../../components/AxiosCall";
import dayjs from "dayjs";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";
import generate_local_timezone_based_iso_date from "../../components/generate_local_timezone_based_iso_date";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


function parse_variable_name(variable_name) {
  let words = variable_name.split("_");
  words = words.map(word => {
    let firstLetter = word.charAt(0).toUpperCase();
    let restOfWord = word.slice(1);
    return firstLetter + restOfWord;
  });
  return words.join(" ");
}


function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        p: 1, pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h1"
          id="tableTitle"
          component="div"
        >
          View Leads
        </Typography>
      )}

      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )} */}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


function LeadsManagement({ user }) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [maxPagesNavigated, setMaxPagesNavigated] = React.useState(-1);
  const [maxSubmissions, setMaxSubmissions] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  const [search, set_search] = React.useState("");
  const [start_date, set_start_date] = React.useState(dayjs().subtract(30, 'day'));
  const [end_date, set_end_date] = React.useState(dayjs(new Date().date));
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, set_loading] = useState(false);
  const [alert, set_alert] = useState(false);
  const [form_submissions, set_form_submissions] = useState([]);
  const [events, set_events] = useState([]);
  const [event_name, set_event_name] = useState("");

  const timeout = useRef(null);


  let columns = [];

  if (form_submissions.length > 0) {
    // maxDict = form_submissions[0];
    // maxNumElements = Object.keys(form_submissions[0]).length;
    // for (let i = 1; i < form_submissions.length; i++) {
    //   const numElements = Object.keys(form_submissions[i]).length;
    //   if (numElements > maxNumElements) {
    //     maxNumElements = numElements;
    //     maxDict = form_submissions[i];
    //   }
    // }
    const maxDict = form_submissions.reduce((acc, cur) => {
      Object.keys(cur).forEach(key => {
        if (!acc.hasOwnProperty(key)) {
          acc[key] = cur[key];
        }
      });
      return acc;
    }, {});

    columns = Object.keys(maxDict)
  }

  function EnhancedTableHead(props) {

    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
      props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (

      <TableHead>
        <TableRow >
          {/* <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell> */}
          {columns.map((column, index) => (
            column != "id" ?
              <TableCell
                key={column}
                id={column}

                sortDirection={orderBy === column ? order : false}
              >
                <TableSortLabel
                  active={orderBy === column}
                  direction={orderBy === column ? order : 'asc'}
                  onClick={createSortHandler(column)}
                >
                  {parse_variable_name(column)}
                  {orderBy === column ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell> : ""
          ))}
        </TableRow>
      </TableHead>


    );
  }

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {

      const newSelected = form_submissions.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (maxPagesNavigated < page)
      setMaxPagesNavigated(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };


  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty form_submissions.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - form_submissions.length) : 0;


  const export_data_as_excel = async (with_date_range) => {
    let url = "/lead-generation/";
    let body = {}
    if (with_date_range) {
      url += "get-all-form-submissions-with-filters-applied/";
      const parsed_start_date = generate_local_timezone_based_iso_date(new Date(start_date), true)
      const parsed_end_date = generate_local_timezone_based_iso_date(new Date(end_date), true)
      body['start_date'] = parsed_start_date;
      body['end_date'] = parsed_end_date;
      body['search'] = search;
      body['event_name'] = event_name;
    }
    else {
      url += "get-all-form-submissions/";

    }
    let res = await axios_get_call(
      url,
      body,
      set_loading
    );

    if (res.data.status == 1) {
      set_loading(false);
      let form_submissions_data = res.data.response;
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(form_submissions_data);
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      const wbout = XLSX.write(wb, { type: 'array', bookType: 'xlsx' });
      const blob = new Blob([wbout], { type: 'application/octet-stream' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'All Submissions Data.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    } else if (res.data.status == 0) {
      set_loading(false);
      set_alert(res.data.response);
    }
  };
  const get_form_submissions = async (refresh) => {
    if (page > maxPagesNavigated) {
      let url = "/lead-generation/get-form-submissions/";

      const parsed_start_date = generate_local_timezone_based_iso_date(new Date(start_date), true)
      const parsed_end_date = generate_local_timezone_based_iso_date(new Date(end_date), true)
      let res = await axios_get_call(
        url,
        {
          "rows_per_page": rowsPerPage,
          "page_number": page,
          "search": search,
          "start_date": parsed_start_date,
          "end_date": parsed_end_date,
          "event_name": event_name
        },
        set_loading
      );

      if (res.data.status == 1) {
        set_loading(false);
        if (refresh) {
          set_form_submissions(res.data.response.form_submissions);
        } else {
          let form_submissions_data = JSON.parse(JSON.stringify(form_submissions)).concat(res.data.response.form_submissions)
          set_form_submissions(form_submissions_data);
        }
        setPage(res.data.response.page_number);

        setMaxSubmissions(res.data.response.max_submissions);
      } else if (res.data.status == 0) {
        set_loading(false);
        set_alert(res.data.response);
      }
    }
  };

  const get_events = async () => {

    let url = "/lead-generation/get-events/";

    let res = await axios_get_call(
      url,
      {

      },
      set_loading
    );

    if (res.data.status == 1) {
      set_loading(false);
      set_events(res.data.response);
      set_start_date(dayjs(res.data.response[0]["start_date"]));
      set_end_date(dayjs(res.data.response[0]["end_date"]))

    } else if (res.data.status == 0) {
      set_loading(false);
      set_alert(res.data.response);
    }
  }

  const difference_in_days = (starting_date, ending_date) => {
    const start_date = new Date(starting_date);
    const end_date = new Date(ending_date);

    const time_diff = Math.abs(end_date.getTime() - start_date.getTime());
    const days_diff = Math.ceil(time_diff / (1000 * 3600 * 24));
    return <Typography variant="subtitle2">Event date range: {days_diff} days</Typography>
  }

  const get_month_name = (date) => {
    const month = date.toLocaleString('default', { month: 'long' });
    return month
  }

  useEffect(() => {
    if (event_name)
      get_form_submissions(false);
  }, [page]);


  useEffect(() => {
    if (event_name)
      get_form_submissions(true);
  }, [rowsPerPage, start_date, end_date, search]);


  useEffect(() => {
    get_events();
  }, []);
  useEffect(() => {
    if (!window.localStorage.getItem("access")) {
      navigate("/login")
    }
  }, [])
  useEffect(() => {
    if (user)
      if (user.location_name == null) navigate("/complete-profile");
      else if (user.name == null) navigate("/complete-profile");

      else if (user.location_phone == null) navigate("/complete-profile");
      else if (user.location_page_url == null) navigate("/complete-profile");
      else if (user.location_address == null) navigate("/complete-profile");
      else if (user.location_city_or_state == null)
        navigate("/complete-profile");


  }, [])

  return (

    <Box
      sx={{
        display: "flex",
        minHeight: "81.8vh",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {alert ? (
        <Alert
          onClose={() => {
            set_alert(false);
          }}
          severity={alert.toLowerCase().includes("[error]") ? "error" : "success"}
          sx={{
            p: 2,
            width: 1,
            zIndex: 9923213,
            mx: -2,
            my: 2,
          }}
        >
          {alert}
        </Alert>
      ) : (
        ""
      )}
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n.MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiPaper-root MuiMenu-paper MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation8 MuiPopover-paper css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper{\nz-index:9232140 !important;\n}\n",
        }}
      />


      <Paper sx={{ width: '95%', mb: 2, borderRadius: 5, p: 2, }}>

        <EnhancedTableToolbar numSelected={selected.length} />
        <Divider />

        <Box sx={{ m: 2, display: "flex", flexDirection: { lg: "row", md: "row", sm: "column-reverse", xs: "column-reverse" }, justifyContent: "space-between", alignItems: "center", textAlign: "center" }}>
            {loading ?  <Box sx={{ flex: 2 }}><CircularProgress /></Box> : 
          <Box sx={{ flex: 2, display: "flex", flexDirection: { lg: "row", md: "column", sm: "column", xs: "column" }, m: 2, margin: { lg: "initial", md: 2, sm: 2, xs: 2 }, }}>
            {/* <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Compact view"
              labelPlacement="start"
            /> */}
            <Button fullWidth onClick={() => export_data_as_excel(true)} sx={{ border: { lg: "none", md: "1px solid #042b48", sm: "1px solid #042b48", xs: "1px solid #042b48" }, }}>
              Export with filters applied
            </Button>
            <Button fullWidth variant="outlined" onClick={() => export_data_as_excel(false)}>
              Export All
            </Button>
          </Box>
          }
          <Box sx={{ flex: 2, m: 2, textAlign: "center", margin: 2 }}>
            <TextField fullWidth label="Search" id="Search" placeholder="by all variables" onChange={(e) => {
              setPage(0);
              clearTimeout(timeout.current);
              timeout.current = setTimeout(async () => {
                set_search(e.target.value)
              }, 1125);

            }} />
          </Box>
          <Tooltip title="Filter based on date range" placement="top">
            <Box sx={{ flex: 2, m: 2, display: "flex", flexDirection: "column", margin: { lg: "initial", md: 2, sm: 2, xs: 2 }, "align-items": "center" }}>
              <Box sx={{ flex: 1, width: "-webkit-fill-available" }}>
                <FormControl sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: { lg: "space-between", md: "left", sm: "center", xs: "center" } }}>
                  <InputLabel id="demo-simple-select-label">Event</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(e) => {

                      set_event_name(e.target.value.title);
                      set_start_date(dayjs(e.target.value.start_date));
                      set_end_date(dayjs(e.target.value.end_date));
                    }}
                  >
                    {events.length > 0 ? events.map((event) => {
                      return (
                        <MenuItem value={event}>{event.title} ({get_month_name(new Date(event.start_date))} - {get_month_name(new Date(event.end_date))})</MenuItem>
                      )
                    }) : <MenuItem value={{}}>No events found</MenuItem>}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ flex: 1, width: "-webkit-fill-available" }}>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: { lg: "space-between", md: "left", sm: "center", xs: "center" }, }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      <DatePicker
                        label="Start Date"
                        value={start_date}
                        onChange={(date) => {
                          setPage(0)
                          set_start_date(date)
                        }}
                        showDaysOutsideCurrentMonth={true}
                        disableHighlightToday
                        reduceAnimations
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Box>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: { lg: "space-between", md: "left", sm: "left", xs: "center" }, }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      <DatePicker
                        label="End Date"
                        value={end_date}
                        onChange={(date) => {
                          setPage(0)
                          set_end_date(date)
                        }}
                        showDaysOutsideCurrentMonth={true}
                        disableHighlightToday
                        reduceAnimations
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Box>
              </Box>

            </Box></Tooltip>
        </Box>
        <Divider sx={{ mb: 0.7 }} />
        {
          form_submissions.length > 0 ?

            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={form_submissions.length}
                />
                <TableBody>
                  {stableSort(form_submissions, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {


                      const isItemSelected = isSelected(row.name);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (


                        <TableRow
                          hover
                          // onClick={(event) => handleClick(event, row.name)}

                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >

                          {/* <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell> */}

                          {columns.map((column, index) => (
                            column != 'id' ?
                              <TableCell component="th"
                                scope="row"
                                id={index}
                                sx={{ p: 1, pl: 2 }}
                                key={index}
                                align="left"
                                padding="none">{typeof row[column] == "undefined" ? "-" : typeof row[column] == "boolean" ? row[column] == true ? "Yes" : "No" : row[column]}</TableCell>
                              : ""
                          ))}

                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            : <Typography variant="subtitle2" margin={4} align="center">{event_name ? "No submissions found" : "Please select an event first"}</Typography>
        }
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Box>
            {difference_in_days(start_date, end_date)}
          </Box>
          <Box>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15, 25, 50]}
              component="div"
              count={maxSubmissions}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Box>
      </Paper >

    </Box >
  );
}

const mapStateToProps = (state) => ({
  state: state,
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(LeadsManagement);
