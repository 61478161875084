import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CompleteProfile from "./CompleteProfile";

function Profile({ user }) {
  const navigate = useNavigate()
  useEffect(() => {
    if (!window.localStorage.getItem("access")) {
      navigate("/login")
    }
    if (user)
      if (user.location_name == null) navigate("/complete-profile");
      else if (user.name == null) navigate("/complete-profile");

      else if (user.location_phone == null) navigate("/complete-profile");
      else if (user.location_page_url == null) navigate("/complete-profile");
      else if (user.location_address == null) navigate("/complete-profile");
      else if (user.location_city_or_state == null)
        navigate("/complete-profile");
  }, [])
  return (
    user ?
      <Box sx={user.under_review && !(user.is_superuser) ? { display: "flex", minHeight: "92vh", flexDirection: "column", alignItems: "center", justifyContent: "center" } : ""}>
        {user.under_review && !(user.is_superuser) ?
          <Typography variant="subtitle2">
            By default your account is under review. After you get approval you will be able to access the website. Please check after some time.
          </Typography>
          :
          <Box mb={10}>
            <Typography variant="h1">
              Welcome {user ? user.name : ""}!
            </Typography>
            <CompleteProfile go_to_calendar={true} />
          </Box>
        }
      </Box>
      : ""
  )
}

const mapStateToProps = (state) => ({
  state: state,
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(Profile);
