import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, CircularProgress, Typography } from '@mui/material'

import axios from 'axios';
import csrf_grabber from '../../components/csrf_grabber';
import CustomModal from '../../components/CustomModal';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

const VerifyHighLevelCRM = ({ user }) => {

    var csrftoken = csrf_grabber('csrftoken');
    const search = useLocation().search;
    const code = new URLSearchParams(search).get("code");
    const [alert, set_alert] = useState(false);
    const [loading, set_loading] = useState(false);
    const [confirmation, set_confirmation] = useState(false);

    const navigate = useNavigate()

    const get_confirmation = async () => {
        set_loading(true)
        const config = {
            params: { code, "callback_url":`${process.env.REACT_APP_FRONTEND_URL}/high-level-crm-verification` },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access')}`,
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'X-CSRFToken': csrftoken
            },
        };

        const res = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/auth/authenticate-high-level-crm/`,
            config
        );
        if (res.data.status == 1) {
            set_loading(false)
            set_confirmation(res.data.response);
        } else {
            set_loading(false)
            set_alert(res.data.response)
            set_confirmation("Authentication failed");
        }
    }
    useEffect(() => {
        if (!(localStorage.getItem('access'))) {
            navigate('/login')
        }
    }, [])

    useEffect(() => {
        get_confirmation()
    }, [])

    return (
        <Box sx={{ minHeight: '80vh' }}>

            {alert ? (
                <Alert
                    onClose={() => {
                        set_alert(false);
                    }}
                    severity='error'
                    sx={{
                        p: 2,
                        width: 1,
                        zIndex: 9923213,
                    }}
                >
                    {alert}
                </Alert>
            ) : (
                ''
            )}
            <Typography sx={{ my: 2 }} align='center' variant='h1'>Authenticating High Level CRM
            </Typography>
            {confirmation ?
                <Typography align='center' variant='subtitle2'>{confirmation}
                </Typography>
                :
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                </Box>
            }
        </Box>
    )
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
});

export default connect(mapStateToProps, {})(VerifyHighLevelCRM);