import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { connect } from "react-redux";
import {
  login,
  facebookAuthenticate,
  googleAuthenticate,
} from "../../actions/auth";

import site_logo from "../../static/media/site logo.png";
// UI elements
import { TextField, Button, Container, CircularProgress, Boxider, Alert, Typography } from "@mui/material";
import { Box } from "@mui/system";

const Login = ({
  login,
  user,
  facebookAuthenticate,
  googleAuthenticate,
}) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;
  const [alert, set_alert] = useState(false);

  const [loading, set_loading] = useState(false);
  const navigate = useNavigate();

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();

    set_loading(true)
    await login(email, password, set_alert);
    set_loading(false)
  };
  // useEffect(() => {
  //   const initClient = () => {
  //     gapi.auth2.init({
  //       client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  //       scope: 'email profile',
  //       fetch_basic_profile: false
  //     });
  //   };
  //   gapi.load('client:auth2', initClient);
  // });

  const continueWithGoogle = (res) => {
    googleAuthenticate(res.accessToken);
  };

  const continueWithFacebook = (res) => {
    facebookAuthenticate(res.accessToken);
    // try {
    //     const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/auth/o/facebook/?redirect_uri=${process.env.REACT_APP_FRONTEND_URL}/facebook`)

    //     window.location.replace(res.data.authorization_url);
    // } catch (err) {

    // }
  };
  useEffect(() => {


    if (user) {
      if (localStorage.getItem("access")) {
        navigate("/calendar");
      }
    }
  }, [user])
  return (
    <Box className="container">
      <Box
        sx={{
          display: "inline-flex",
          flexDirection: "column",
          minHeight: "100vh",
          maxHeight: "100%",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",

          "": "",
        }}
      >
        <img src={site_logo} style={{ marginBottom: 40 }} width={'200px'} />
        <Typography variant="h1" sx={{ margin: 0 }}>Login</Typography>
        <Typography variant="subtitle2">Log into your Account</Typography>
        <br />


        <Box
          sx={{
            display: "flex",
            "flex-direction": "column",
            alignItems: "center",
          }}
        >
          <Box
            component={"form"}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              minWidth: { xs: "100%", sm: "100%", md: "350px" },
              m: "auto",
            }}
            onSubmit={(e) => onSubmit(e)}
          >
            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              margin="dense"
              size="small"
              type="email"
              name="email"
              value={email}
              onChange={(e) => onChange(e)}
              required
              fullWidth
            />
            <TextField
              id="outlined-basic"
              label="Password"
              variant="outlined"
              margin="dense"
              size="small"
              type="password"
              name="password"
              value={password}
              onChange={(e) => onChange(e)}
              minLength="1"
              required
              fullWidth
            />

            {alert ? (
              <Alert
                onClose={() => {
                  set_alert(false);
                }}
                severity={alert.toLowerCase().includes("[error]") ? "error" : "success"}
                sx={{

                  m: "auto",
                  mb: 2,
                  maxWidth: "350px",
                  zIndex: 9923213,
                }}
              >
                {alert}
              </Alert>
            ) : (
              ""
            )}

            <Button
              variant="contained"
              type="submit"
            >

              {loading ? <CircularProgress color='secondary' /> : 'Login'}
            </Button>
          </Box>
          <br />
          {/* <Boxider flexItem>
            or login with
          </Boxider>
          <br />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "140%",
            }}
          >
            <Box sx={{ flex: 1 }}>
              <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                buttonText="CONTINUE WITH GOOGLE"
                render={renderProps => (
                  <Button variant="outlined" fullWidth onClick={renderProps.onClick} disabled={renderProps.disabled}>Continue with Google</Button>
                )}

                className="kep-login-google"
                onSuccess={continueWithGoogle}
                onFailure={continueWithGoogle}
                redirect_uri={`${process.env.REACT_APP_FRONTEND_URL}/google`}
                cookiePolicy={"single_host_origin"}
              />
            </Box>

            <Box sx={{ minWidth: "100%" }}>
              <FacebookLogin
                appId={process.env.REACT_APP_FACEBOOK_APP_ID}


                fields="email, username"
                callback={continueWithFacebook}
                render={renderProps => (
                  <Button variant="outlined" fullWidth onClick={renderProps.onClick}>Continue with Facebook</Button>
                )}
              />
            </Box>
          </Box> */}
        </Box>
        <br />
        <Typography variant="subtitle2" align="center"  >
          Don't have an account?{" "}
          <Link
            to="/signup"
            className="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-k7pzs0-MuiTypography-root-MuiLink-root"
          >
            Sign Up
          </Link>
        </Typography>
        <Typography variant="subtitle2" align="center"  >
          Forgot your Password?{" "}
          <Link
            to="/reset-password"
            className="MuiTypography-root MuiTypography-inherit MuiLink-root MuiLink-underlineAlways css-k7pzs0-MuiTypography-root-MuiLink-root"
          >
            Reset Password
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  login,
  facebookAuthenticate,
  googleAuthenticate,
})(Login);