import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

function HomePage({ user }) {
    const navigate = useNavigate()
    return (
        <Box sx={{ minHeight: "81.85vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Box sx={{ alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                <Typography
                    variant="h1"

                >
                    <u>Select any feature</u>
                </Typography>
                <Box sx={{ display: 'flex' }}>
                    
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    {/* Dashboard */}
                    <Box onClick={() => { navigate("/dashboard") }} sx={{ flex: 1, maxHeight: '100px', display: "flex", alignItems: 'center', margin: 2, bgcolor: "white", padding: 5, borderRadius: 4, "&:hover": { bgcolor: "gray", color: 'white', cursor: "pointer" } }}>
                        <Typography
                            variant="h1"
                            fontWeight={500}
                        >
                            Dashboard
                        </Typography>
                    </Box>
                    {/* Calendar */}
                    <Box onClick={() => { navigate("/calendar") }} sx={{ flex: 1, maxHeight: '100px', display: "flex", alignItems: 'center', margin: 2, bgcolor: "white", padding: 5, borderRadius: 4, "&:hover": { bgcolor: "gray", color: 'white', cursor: "pointer" } }}>
                        <Typography
                            variant="h1"
                            fontWeight={500}
                        >
                            Calendar
                        </Typography>
                    </Box>
                    </Box>
                    {/* Events */}
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Box onClick={() => { navigate(user ? user.form_id ? `/form/${user.form_id}` : "/login" : "/login") }} sx={{ flex: 1, maxHeight: '100px', display: "flex", alignItems: 'center', margin: 2, bgcolor: "white", padding: 5, justifyContent: "center", borderRadius: 4, fontWeight: 600, "&:hover": { bgcolor: "gray", color: 'white', cursor: "pointer" } }}>
                            <Typography

                                variant="h1"
                                fontWeight={500}
                            >
                                Event form
                            </Typography>
                        </Box>
                        <Box onClick={() => { navigate("view-leads") }} sx={{ flex: 1, margin: 2, bgcolor: "white", padding: 5, borderRadius: 4, "&:hover": { bgcolor: "gray", color: 'white', cursor: "pointer" } }}>
                            <Typography
                                variant="h1"
                                fontWeight={500}
                            >
                                Form submissions
                            </Typography>
                        </Box>
                        <Box onClick={() => { navigate("raffle") }} sx={{ flex: 1, margin: 2, bgcolor: "white", padding: 5, borderRadius: 4, "&:hover": { bgcolor: "gray", color: 'white', cursor: "pointer" } }}>
                            <Typography
                                variant="h1"
                                fontWeight={500}
                            >
                                Raffle
                            </Typography>
                        </Box>
                    </Box>
                </Box>

            </Box>
        </Box>
    )
}

const mapStateToProps = (state) => ({
    state: state,
    user: state.auth.user,
});

export default connect(mapStateToProps, {})(HomePage);
