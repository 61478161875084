import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { connect } from 'react-redux';

const HelpLinks = ({ user }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Typography
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ color: "white", p: 1, borderRadius: 12, px: 2, cursor: "pointer", fontWeight: "600", ":hover": { bgcolor: "rgba(225,225,225,0.1)" } }}
      >
        Help
      </Typography>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => {
          // navigate("/view-leads");
          handleClose();
        }}>Tutorials (Coming Soon)</MenuItem>
        <MenuItem onClick={() => {
          // navigate("/view-leads");
          handleClose();
        }}>Support (Coming Soon)</MenuItem>
      </Menu>
    </div>
  );
}

const mapStateToProps = (state) => ({
  state: state,
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(HelpLinks);
