import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import AlertManager from "./AlertManager";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

/* Alert manager is used by alerts(popup messages that occur when an action is performed by user)
   to display themselves without breaking the react component logic by appending them in runtime using vanilla JavaScript DOM */
ReactDOM.render(<AlertManager />, document.getElementById("AlertsManager"));
