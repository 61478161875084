import React, { useState, useRef, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "./hocs/Layout";
import './App.css';

import HomePage from "./containers/base/HomePage";
import PageNotFound from "./containers/base/PageNotFound";

// Authentication containers
import Login from "./containers/authentication/Login";
import Signup from "./containers/authentication/Signup";
import Activate from "./containers/authentication/Activate";
import ResetPassword from "./containers/authentication/ResetPassword";
import ResetPasswordConfirm from "./containers/authentication/ResetPasswordConfirm";

// SaaS containers
// Dashboard
import Dashboard from "./containers/saas/Dashboard/Dashboard";
// Dashboard>Integrations
import FacebookConnect from "./containers/saas/Dashboard/Integrations/FacebookConnect";
// SaaS 
import Profile from "./containers/saas/Profile";
import VerifyHighLevelCRM from "./containers/saas/VerifyHighLevelCRM";
import PublicForm from "./containers/saas/PublicForm";
import LeadsManagement from "./containers/saas/LeadsManagement";
import CompleteProfile from "./containers/saas/CompleteProfile";
import CalendarPage from "./containers/saas/Calendar";
import ViewOrCreatePost from "./containers/saas/ViewOrCreatePost";
import Raffle from "./containers/saas/Raffle";

// Redux Store
import { Provider } from "react-redux";
import store from "./store";

const App = () => {
  const mounted = useRef();

  // useEffect(() => {
  //   if (!mounted.current) {
  //     window.gtag("config", "UA-216385747-1", {
  //     page_title: window.location.pathname,
  //     page_path: window.location.pathname,
  //     })
  //     mounted.current = true;
  //   } else {
  //     // do elementDi<dUpdate /> logic
  //   }
  // });


  return (
    <Provider store={store}>
      <Router>
        <Layout>
          <Routes>
            <Route path="*" element={<PageNotFound />} />

            {/* // Authentication routes */}
            <Route exact path="/" element={<HomePage />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/signup" element={<Signup />} />
            <Route exact path="/reset-password" element={<ResetPassword />} />
            <Route
              exact
              path="/account-activation-email-sent"
              element={<ResetPassword />}
            />
            <Route
              exact
              path="/password/reset/confirm"
              element={<ResetPasswordConfirm />}
            />
            <Route
              exact
              path="/password/reset/confirm/:uid/:token"
              element={<ResetPasswordConfirm />}
            />
            <Route exact path="/activate" element={<Activate />} />

            {/* User Routes */}
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/facebook-connect/" element={<FacebookConnect />} />
            <Route exact path="/complete-profile" element={<CompleteProfile />} />
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/post" element={<ViewOrCreatePost />} />
            <Route exact path="/raffle" element={<Raffle />} />
            <Route exact path="/high-level-crm-verification" element={<VerifyHighLevelCRM />} />
            <Route exact path="/calendar" element={<CalendarPage />} />
            <Route exact path="/form/:str" element={<PublicForm />} />
            <Route exact path="/view-leads" element={<LeadsManagement />} />

          </Routes>
        </Layout>
      </Router>
    </Provider>
  );
};

export default App;
