import { Alert, Button, CircularProgress, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { axios_post_call } from '../../components/AxiosCall';
import {
    load_user,
} from "../../actions/auth";
function CompleteProfile({ user, go_to_calendar }) {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [loading, set_loading] = useState(false);
    const [alert, set_alert] = useState(false);
    const [go_to_dashboard, set_go_to_dashboard] = useState(false);

    const [name, set_name] = useState("");
    const [disable_email_edit, set_disable_email_edit] = useState(true);
    const [email, set_email] = useState("");

    const [high_level_crm_location_auth_key, set_high_level_crm_location_auth_key] = useState("");

    const [location_name, set_location_name] = useState("");
    const [location_phone, set_location_phone] = useState("");
    const [location_page_url, set_location_page_url] = useState("");
    const [location_address, set_location_address] = useState("");
    const [location_city_or_state, set_location_city_or_state] = useState("");

    useEffect(() => {
        if (user) {
            set_email(user.email)
            set_name(user.name)

            set_high_level_crm_location_auth_key(user.high_level_crm_location_auth_key)


            set_location_name(user.location_name)
            set_location_phone(user.location_phone)
            set_location_page_url(user.location_page_url)
            set_location_address(user.location_address)
            set_location_city_or_state(user.location_city_or_state)
        }
    }, [user])

    const update_user_profile_data = async (e) => {
        set_alert(false)
        e.preventDefault();
        let url = "/auth/update-user-profile-data/"

        let res = await axios_post_call(url, { email, name, high_level_crm_location_auth_key, location_name, location_phone, location_page_url, location_address, location_city_or_state }, set_loading)
        set_loading(false)

        if (res.data.status == 1) {
            set_alert(res.data.response);
            set_go_to_dashboard(true)
            dispatch(load_user())
            navigate("/profile")
        }
        else if (res.data.status == 0) {
            set_alert(res.data.response);

        }
    }

    useEffect(() => {
        if (!window.localStorage.getItem("access")) {
            navigate("/login")
        }

    }, [])
    return (
        <Box sx={{
            width: { xl: "30%", lg: "40%", md: "50%", sm: "70%", xs: "100%" },
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        }}>
            {alert ?
                <Alert onClose={() => { set_alert(false) }} severity={alert.toLowerCase().includes("[error]") ? "error" : "success"} sx={{
                    p: 2, width: 1,
                    mb: 2,
                    zIndex: 9923213,
                }}>{alert}</Alert>
                : ""}

            {user ? go_to_dashboard && user.under_review ?
                <Alert onClose={() => { set_alert(false) }} severity="error" sx={{
                    p: 2, width: 1,
                    mb: 2,
                    zIndex: 9923213,
                }}>Your account is now under approval please come back later
                </Alert>
                : "" : ""}


            <Typography variant="h1" mt={4}>
                {user ? user.location_name ? "" : "Please complete the profile" : "Please complete the profile"}
            </Typography>
            <Box sx={{
                width: '90%', display: 'flex', flexDirection: "column", m: 4
            }} component='form' onSubmit={(e) => { update_user_profile_data(e) }}>
                {/* Name */}
                <Box sx={{ flex: 1, mb: 2 }}>
                    <Typography variant="subtitle2" align="left">
                        Name
                    </Typography>
                    <TextField placeholder='Your name' required fullWidth size="small" value={name} onChange={(e) => set_name(e.target.value)} />
                </Box>
                {/* Email */}
                <Box sx={{ flex: 1, mb: 2 }}>
                    <Typography variant="subtitle2" align="left">
                        Email
                    </Typography>
                    <TextField placeholder='Your name' disabled={disable_email_edit} onDoubleClick={() => set_disable_email_edit(false)} required fullWidth size="small" type='email' value={email} onChange={(e) => set_email(e.target.value)} helperText={disable_email_edit ? "Double click to edit email " : ""} />
                </Box>



                {/* Location Name */}
                <Box sx={{ flex: 1, mb: 2 }}>
                    <Typography variant="subtitle2" align="left">
                        Location Name
                    </Typography>
                    <TextField required fullWidth size="small" value={location_name} onChange={(e) => set_location_name(e.target.value)} />
                </Box>
                {/* Location Phone */}
                <Box sx={{ flex: 1, mb: 2 }}>
                    <Typography variant="subtitle2" align="left">
                        Location Phone
                    </Typography>
                    <TextField required type="tel" fullWidth size="small" value={location_phone}


                        placeholder="(123) 456-7890"
                        // pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"

                        onChange={(e) => {
                            let cleaned = e.target.value.replace(/[^\d+]/g, '');
                            // Handle backspace
                            if (e.nativeEvent.inputType === 'deleteContentBackward') {
                                cleaned = cleaned.slice(0, -1); // Remove the last digit
                            }
                            // Extract the three groups of digits: area code, prefix, and line number
                            const areaCode = cleaned.substring(0, 3);
                            const prefix = cleaned.substring(3, 6);
                            const lineNumber = cleaned.substring(6);


                            // Format the phone number using the desired pattern
                            const formattedPhoneNumber = `(${areaCode}) ${prefix}-${lineNumber}`;

                            if (formattedPhoneNumber.length <= 20)
                                set_location_phone(formattedPhoneNumber);
                        }
                        } />

                </Box>
                {/* Location Page URL */}
                <Box sx={{ flex: 1, mb: 2 }}>
                    <Typography variant="subtitle2" align="left">
                        Location Page URL
                    </Typography>
                    <TextField required type="url" placeholder="https://" fullWidth size="small" value={location_page_url} onChange={(e) => set_location_page_url(e.target.value)} />
                </Box>
                {/* Location Phone */}
                <Box sx={{ flex: 1, mb: 2 }}>
                    <Typography variant="subtitle2" align="left">
                        Location Address
                    </Typography>
                    <TextField required fullWidth size="small" value={location_address} onChange={(e) => set_location_address(e.target.value)} />
                </Box>
                {/* Location City */}
                <Box sx={{ flex: 1, mb: 2 }}>
                    <Typography variant="subtitle2" align="left">
                        Location City/State
                    </Typography>
                    <TextField required fullWidth size="small" value={location_city_or_state} onChange={(e) => set_location_city_or_state(e.target.value)} />
                </Box>
                {/* CRM API Key */}
                <Box sx={{ flex: 1, mb: 2 }}>
                    <Typography variant="subtitle2" align="left">
                        High Level CRM Location Authentication key
                    </Typography>
                    <TextField fullWidth size="small" value={high_level_crm_location_auth_key} onChange={(e) => set_high_level_crm_location_auth_key(e.target.value)} placeholder='eyJhbGciOiJIUzI00000000000.0000000000000.000000000000000000000000' />
                </Box>

                {/* Submit button */}
                <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "column", md: "row" } }}>

                    {go_to_calendar || go_to_dashboard ?
                        <Button fullWidth sx={{ mb: 2, flex: 2 }} variant="outlined" onClick={() => navigate("/calendar")}>
                            View Marketing Calendar
                        </Button>

                        : ""}
                    <Button fullWidth sx={{ mb: 2, flex: 1, whiteSpace: "nowrap" }} variant='contained' type="submit" >
                        {loading ? <CircularProgress color='secondary' /> : user ? go_to_dashboard ? "Update Profile" : user.location_name ? "Update Profile" : "Submit" : "Submit"}
                    </Button>
                </Box>
            </Box>

        </Box>
    )
}

const mapStateToProps = (state) => ({
    state: state,
    user: state.auth.user,
});

export default connect(mapStateToProps, {})(CompleteProfile);
