import { Box } from "@mui/system";
import {
  Alert,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import PhoneInput from 'react-phone-input-2'
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import DeleteIcon from "@mui/icons-material/Delete";
import { axios_get_call, axios_post_call } from "../../components/AxiosCall";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import favicon_low_res from "../../static/media/favicon low-res.png";
import QrCodeIcon from '@mui/icons-material/QrCode';
import { QRCode } from "react-qrcode-logo";
import html2canvas from 'html2canvas';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import generate_local_timezone_based_iso_date from "../../components/generate_local_timezone_based_iso_date";
import { useRef } from "react";



function PublicForm({ user }) {
  const navigate = useNavigate();
  const location = useLocation();
  const qrCodeRef = useRef(null);

  const [loading, set_loading] = useState(false);
  const [alert, set_alert] = useState(false);
  const [form_submitted, set_form_submitted] = useState(false);

  const [start_date, set_start_date] = React.useState(dayjs().subtract(30, 'day'));
  const [end_date, set_end_date] = React.useState(dayjs(new Date().date));

  const [radio_options, set_radio_options] = useState([]);
  const [number_of_radio_options, set_number_of_radio_options] = useState(1);
  const [add_another_field_form, set_add_another_field_form] = useState(false);
  const [name, set_name] = useState("");
  const [email, set_email] = useState("");
  const [phone_number, set_phone_number] = useState("");
  const [gender, set_gender] = useState("");
  const [
    have_you_been_to_chiropractic_services_before,
    set_have_you_been_to_chiropractic_services_before,
  ] = useState(false);
  const [edit_form_title, set_edit_form_title] = useState(false);
  const [form_title, set_form_title] = useState("Form Title");
  const [type, set_type] = useState("");
  const [label, set_label] = useState("");
  const [variable_name, set_variable_name] = useState("");
  const [required, set_required] = useState(false);
  const [placeholder, set_placeholder] = useState("");
  const [form_fields, set_form_fields] = useState([]);

  const handleInputChange = (index, event) => {
    let { id, name, value, type, required } = event.target;
    const fields = [...form_fields];
    if (!isNaN(value)) { value = parseInt(value) }
    fields[index] = { label: id, variable_name: name, value, type, required };
    set_form_fields(fields);
  };
  const add_field = () => {
    let new_form_question = {};
    new_form_question["type"] = type;
    new_form_question["label"] = label;
    new_form_question["variable_name"] = variable_name;
    new_form_question["required"] = required;
    new_form_question["placeholder"] = placeholder;
    if (type == "radio") {
      new_form_question["value"] = radio_options;
      new_form_question["answer"] = "None";
    } else {
      if (type == "toggle")
        new_form_question["value"] = false;
      else
        new_form_question["value"] = "";

    }

    if (typeof add_another_field_form === "boolean") {
      set_form_fields([...form_fields, new_form_question]);
      set_type("");
      set_label("");
      set_variable_name("");
      set_required("");
      set_placeholder("");
    } else {
      const fields = [...form_fields];
      fields[add_another_field_form] = new_form_question;
      set_form_fields(fields);
    }
    set_add_another_field_form(false);
  };
  const delete_field = (index) => {
    let updated_form_fields = [...form_fields];
    updated_form_fields.splice(index, 1);
    set_form_fields(updated_form_fields);
  };

  const submit_form = async (e) => {
    e.preventDefault();
    let url = "/lead-generation/submit-form/";
    let form_field_answers = JSON.parse(JSON.stringify(form_fields));
    const form_field_answers_parsed = {
      name, email, phone_number,gender,
      have_you_been_to_chiropractic_services_before,
    };
    form_field_answers.forEach((item) => {
      if (item.type == "radio") {
        form_field_answers_parsed[item.variable_name] = item.answer;
      } else if (item.hasOwnProperty("value")) {
        form_field_answers_parsed[item.variable_name] = item.value;
      }
    });

    let res = await axios_post_call(
      url,
      { form_user_name: name, form_user_email: email, form_user_gender:gender, form_user_phone_number: phone_number, form_title, form_field_answers: form_field_answers_parsed, form_id: location.pathname.split("/")[2] },
      set_loading
    );
    set_loading(false);

    if (res.data.status == 1) {
      set_form_submitted(true);
      set_alert(res.data.response);
    } else if (res.data.status == 0) {
      set_alert(res.data.response);
    }
  };
  const save_form_fields = async () => {
    let url = "/lead-generation/save-form-fields/";
    const parsed_start_date = generate_local_timezone_based_iso_date(new Date(start_date), true)
    const parsed_end_date = generate_local_timezone_based_iso_date(new Date(end_date), true)

    let res = await axios_post_call(
      url,
      { form_title, form_fields, start_date: parsed_start_date, end_date: parsed_end_date },
      set_loading
    );
    set_loading(false);

    if (res.data.status == 1) {
      set_alert(res.data.response);
    } else if (res.data.status == 0) {
      set_alert(res.data.response);
    }
  };

  const load_form_fields = async () => {

    let url = "/lead-generation/load-form-fields/";
    let res = await axios_get_call(
      url,
      { form_id: location.pathname.split("/")[2] },
      set_loading
    );

    if (res.data.status == 1) {
      set_loading(false);
      set_form_title(res.data.response.title);
      set_form_fields(res.data.response.fields);
      set_start_date(dayjs(res.data.response.start_date));
      set_end_date(dayjs(res.data.response.end_date));
    } else if (res.data.status == 0) {
      set_loading(false);
      set_alert(res.data.response);
    }
  };

  useEffect(() => {
    load_form_fields();
  }, []);

  useEffect(() => {
    let all_radio_options = JSON.parse(JSON.stringify(radio_options));

    for (
      var i = 0;
      i < Math.abs(parseInt(number_of_radio_options) - radio_options.length);
      i++
    ) {
      if (all_radio_options.length <= parseInt(number_of_radio_options)) {
        all_radio_options.push("Add an option");
      } else {
        all_radio_options.pop(all_radio_options.length, 1);
      }
    }

    set_radio_options(all_radio_options);
  }, [number_of_radio_options]);

  useEffect(() => {
    if (typeof add_another_field_form != "boolean") {
      if (type == "radio") {
        set_number_of_radio_options(
          form_fields[add_another_field_form]["value"].length
        );
        set_radio_options(form_fields[add_another_field_form]["value"]);
      }
    }
  }, [add_another_field_form]);

  useEffect(() => {
    if (user)
      if (user.location_name == null) navigate("/complete-profile");
      else if (user.name == null) navigate("/complete-profile");

      else if (user.location_phone == null) navigate("/complete-profile");
      else if (user.location_page_url == null) navigate("/complete-profile");
      else if (user.location_address == null) navigate("/complete-profile");
      else if (user.location_city_or_state == null)
        navigate("/complete-profile");
  }, [])

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "92vh",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n.MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiPaper-root MuiMenu-paper MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation8 MuiPopover-paper css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper{\nz-index:9232140 !important;\n}\n",
        }}
      />

      {add_another_field_form || add_another_field_form === 0 ? (
        <Box
          sx={{
            "-webkit-text-size-adjust": "100%",
            "-webkit-tap-highlight-color": "transparent",
            "box-sizing": "border-box",
            minHeight: "100vh",
            top: "0",
            right: "0",
            bottom: "0",
            left: "0",
            position: "fixed",
            "z-index": "1050",
            display: "flex",
            outline: "0",
            "text-align": "center",
            padding: "0!important",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: "rgba(0,0,0,0.4)",
            "backdrop-filter": "blur(10px)",
            "overflow-x": "hidden",
            "overflow-y": "auto!important",
            "&:hover": { cursor: "pointer" },
          }}
        >
          <style
            dangerouslySetInnerHTML={{
              __html:
                "\nbody{\n    overflow: hidden !important;\npadding-right: 15px !important;\n}\n",
            }}
          />
          <Box
            id="CustomModal"
            onMouseUp={(e) => {
              let pol = document.getElementById("CustomModal");
              if (e.target == pol) {
                set_add_another_field_form(false);
              }
            }}
            py={4}
            sx={{
              zIndex: 123214,
              margin: "auto",
              width: 1,
              minHeight: 1,
              cursor: "not-allowed",

              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: {
                  xl: "27%",
                  lg: "35%",
                  md: "40%",
                  sm: "50%",
                  xs: "100%",
                },
                h: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                bgcolor: "white",
                cursor: "auto",
                borderRadius: 3,
                boxShadow: 24,
              }}
            >
              <Typography mt={2} variant="h2">
                {typeof add_another_field_form != "boolean"
                  ? "Edit field"
                  : "Add field"}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  p: 2,
                  float: "left",
                  width: "90%",
                  textAlign: "left",
                  margin: "auto",
                }}
              >
                <Typography
                  mt={2}
                  component="label"
                  for={"name"}
                  variant="subtitle2"
                >
                  Input Type
                </Typography>

                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    sx={{
                      bgcolor: "#e8e8e8",
                      width: 1,
                      textAlign: "left",
                      mb: 2,
                    }}
                    value={type}
                    onChange={(e) => set_type(e.target.value)}
                  >
                    <MenuItem sx={{ py: 2 }} value={"text"}>
                      Text
                    </MenuItem>
                    <MenuItem sx={{ py: 2 }} value={"number"}>
                      Number
                    </MenuItem>
                    <MenuItem sx={{ py: 2 }} value={"email"}>
                      Email
                    </MenuItem>
                    <MenuItem sx={{ py: 2 }} value={"radio"}>
                      Radio Input
                    </MenuItem>
                    <MenuItem sx={{ py: 2 }} value={"toggle"}>
                      Toggle Input
                    </MenuItem>
                  </Select>
                </FormControl>
                {type != "" ? (
                  <Box sx={{ width: 1, textAlign: "left" }}>
                    <Typography
                      mt={2}
                      component="label"
                      for={"name"}
                      variant="subtitle2"
                    >
                      {type.toLowerCase() == "radio"
                        ? "Question"
                        : type.toLowerCase() == "toggle"
                          ? "Question/Statement"
                          : "Label"}
                    </Typography>
                    <TextField
                      fullWidth
                      sx={{ bgcolor: "#e8e8e8", mb: 2 }}
                      variant="outlined"
                      value={label}
                      onChange={(e) => {
                        const text = e.target.value;
                        const regex = /[^a-zA-Z]/g;
                        const formattedText = text.replace(regex, "");
                        set_label(text);


                        set_variable_name(formattedText);
                      }
                      }


                    />
                    <Typography
                      mt={2}
                      component="label"
                      for={"name"}
                      variant="subtitle2"
                    >
                      Variable Name
                    </Typography>
                    <TextField
                      fullWidth
                      sx={{ bgcolor: "#e8e8e8", mb: 2 }}
                      variant="outlined"
                      value={variable_name}
                      onChange={(e) => {
                        const formattedText = e.target.value
                          .replaceAll(" ", "_")
                          .toLowerCase();
                        set_variable_name(formattedText);
                      }}
                    />
                    {type.toLowerCase() == "text" ||
                      type.toLowerCase() == "email" ||
                      type.toLowerCase() == "number" ? (
                      <Box sx={{ width: 1, textAlign: "left" }}>
                        <Typography
                          mt={2}
                          component="label"
                          for={"name"}
                          variant="subtitle2"
                        >
                          Placeholder Text
                        </Typography>

                        <TextField
                          fullWidth
                          sx={{ bgcolor: "#e8e8e8" }}
                          variant="outlined"
                          value={placeholder}
                          onChange={(e) => set_placeholder(e.target.value)}
                        />
                      </Box>
                    ) : (
                      ""
                    )}{" "}
                    {type.toLowerCase() != "toggle" ? <>
                      <Typography
                        mt={2}
                        component="label"
                        for={"name"}
                        variant="subtitle2"
                      >
                        Required Field
                      </Typography>
                      <Switch
                        defaultChecked
                        value={required}
                        checked={required}
                        onChange={(e) => {
                          set_required(e.target.checked);
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      /></> : ""}
                  </Box>
                ) : (
                  ""
                )}

                {type.toLowerCase() == "radio" ? (
                  <Box sx={{ width: 1, textAlign: "left" }}>
                    <Typography
                      mt={2}
                      component="label"
                      for={"name"}
                      variant="subtitle2"
                    >
                      Select no of options:
                    </Typography>
                    <TextField
                      fullWidth
                      sx={{ bgcolor: "#e8e8e8" }}
                      variant="outlined"
                      type="number"
                      inputProps={{ className: "number-input" }}
                      onWheel={(e) => {
                        e.target.blur()
                      }}
                      value={number_of_radio_options}

                      onChange={(e) => {
                        let value = e.target.value;
                        if (value > 1) {
                          set_number_of_radio_options(value)
                        } else {
                          set_number_of_radio_options(1);
                        }
                      }
                      }
                    />
                    <Typography mt={4} variant="subtitle2">
                      Options
                    </Typography>
                    {radio_options.map((option, index) => {
                      return (
                        <Box mt={2}>
                          <TextField
                            fullWidth
                            sx={{ bgcolor: "#e8e8e8" }}
                            variant="outlined"
                            placeholder={
                              radio_options[index] == "Add an option"
                                ? radio_options[index]
                                : ""
                            }
                            value={
                              radio_options[index] == "Add an option"
                                ? ""
                                : radio_options[index]
                            }
                            onChange={(e) => {
                              let updated_option = JSON.parse(
                                JSON.stringify(radio_options)
                              );
                              updated_option[index] = e.target.value;
                              set_radio_options(updated_option);
                            }}
                          />
                        </Box>
                      );
                    })}
                  </Box>
                ) : (
                  ""
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  p: 2,
                }}
              >
                <Button
                  variant="outlined"
                  fullWidth
                  sx={{ minHeight: '43px', }}
                  onClick={() => {
                    set_add_another_field_form(false);
                    set_type("");
                    set_label("");
                    set_variable_name("");
                    set_placeholder("");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{ minHeight: '43px', }}
                  onClick={() => {
                    add_field();
                  }}
                >
                  {typeof add_another_field_form === "boolean"
                    ? "Add"
                    : "Update"}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        ""
      )}
      <Box
        sx={{
          bgcolor: "#efefef",
          p: 4,
          borderRadius: 6,
          minHeight: "80vh",
          minWidth: "80%",
          m: 2,
        }}
        component={"form"}
        onSubmit={(e) => {
          submit_form(e);
        }}
      >
        {user ?
          <Box sx={{ mb: 10 }}>
            <Tooltip title="Click to copy the url of this form" placement="left">
              <Box
                id="copy_form_url"
                onClick={() => {
                  let copy_form_url = document.getElementById("copy_form_url");
                  navigator.clipboard.writeText(
                    window.location.origin + window.location.pathname
                  );
                  copy_form_url.style.background = "#04db68";
                  setTimeout(() => {
                    copy_form_url.style.background = "#dddddd";
                  }, 500);
                }}
                sx={{
                  position: "absolute",
                  right: 'calc(10% + 60px)',
                  float: "right",
                  bgcolor: "#dddddd",
                  p: 2,

                  borderRadius: 15,
                  cursor: "pointer",
                }}
              >
                <CopyAllIcon />
              </Box>
            </Tooltip>
            <Tooltip title="Click to download QR code for this form" placement="top">
              <Box
                id="copy_form_url"
                onClick={() => {
                  let qr_code = document.getElementById("qr_code");
                  let qr_code_container = document.getElementById("qr_code_container");
                  qr_code_container.style.display = "block";
                  if (qr_code_container.style.display == "block") {
                    if (qr_code) {
                      html2canvas(qr_code).then((canvas) => {
                        const downloadLink = document.createElement('a');
                        downloadLink.href = canvas.toDataURL('image/png');
                        downloadLink.download = 'qrcode.png';
                        downloadLink.click();
                        qr_code_container.style.display = "none"
                      });
                    }
                  }

                }}
                sx={{
                  position: "absolute",
                  right: '10%',
                  float: "right",
                  bgcolor: "#dddddd",
                  p: 2,
                  borderRadius: 15,
                  cursor: "pointer",
                }}
              >
                <QrCodeIcon />
                <Box id="qr_code_container" sx={{ display: "none" }}>
                  <QRCode
                    ref={qrCodeRef}
                    id="qr_code"
                    value={process.env.REACT_APP_FRONTEND_URL + location.pathname}
                    logoImage={favicon_low_res}
                    logoWidth={43}
                    logoHeight={43}
                    removeQrCodeBehindLogo={true}
                    fgColor="#042b48"
                    qrStyle="squares"
                    eyeRadius={4}
                    quietZone={10}
                    size={250}
                  />
                </Box>
              </Box>
            </Tooltip>
          </Box> : ""}

        {edit_form_title ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center", flexDirection: { xs: "column", sm: "column", md: "row" },
              mb: 4
            }}
          >
            <Typography variant="h2">
              Event:
            </Typography>
            <TextField
              sx={{ bgcolor: "#e8e8e8", width: "80vw", maxWidth: '600px' }}
              inputProps={{
                style: {
                  textAlign: "center",
                },
              }}
              variant="outlined"
              fullWidth
              size="small"
              placeholder={form_title}
              value={form_title}
              onChange={(e) => set_form_title(e.target.value)}
              autoFocus
              onFocus={(e) => e.target.select()}
              helperText="Note: Changing the title will create a new form/event. Any submissions to the new form can be filtered."
            />

            <Button
              onClick={() => {
                set_edit_form_title(false);
              }}
              variant="outlined"
              fullWidth
              color="success"
              sx={{ maxHeight: "62px", minHeight: '62px', borderRadius: 15, maxWidth: "62px", minWidth: '62px', }}
            >
              <DoneIcon />
            </Button>
          </Box>
        ) : (
          <>
            {user ? <Typography
              mb={4}
              align="center"
              sx={{
                bgcolor: "grey",
                color: "white",
                width: "fit-content",
                margin: 'auto',
                mb: 2,
                p: 0.5,
                px: 1,
                borderRadius: 1,
                fontSize: 11
              }}
            >
              Click below to edit the form title
            </Typography>
              : ""}
            <Typography
              onClick={() => {
                if (user) {
                  set_edit_form_title(true);
                }
              }}
              variant="h1"
              sx={{
                p: 1,
                "&:hover": user ? { bgcolor: "#E8E8E8" } : {},
                cursor: "pointer",
                fontSize: { xs: "1.5em", sm: "1.75em", md: "2em" },
              }}
            >
              {form_title}
            </Typography>
          </>
        )}
        {user ?
          <Tooltip title="Filter based on date range" placement="top">
            <Box sx={{ mb: 4, display: "flex", justifyContent: 'center', flexDirection: { xs: "column", sm: "column", md: "row" } }}>
              <Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker']}>
                    <DatePicker
                      label="Event Start Date"
                      value={start_date}
                      onChange={(date) => {

                        set_start_date(date)
                      }}
                      showDaysOutsideCurrentMonth={true}
                      disableHighlightToday
                      reduceAnimations
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Box>
              <Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker']}>
                    <DatePicker
                      label="Event End Date"
                      value={end_date}
                      onChange={(date) => {

                        set_end_date(date)
                      }}
                      showDaysOutsideCurrentMonth={true}
                      disableHighlightToday
                      reduceAnimations
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Box>

            </Box></Tooltip>
          : ""}
        <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
          <Typography component="label" for={"name"} variant="subtitle2">
            Name <span style={{ color: "#e83030" }}>*</span>
          </Typography>
          <TextField
            sx={{ bgcolor: "#e8e8e8" }}
            variant="outlined"
            name={name}
            id={"name"}
            required
            placeholder="Full Name"
            value={name}
            autoComplete="off"
            onChange={(e) => set_name(e.target.value)}
            type="text"
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
          <Typography component="label" for={"name"} variant="subtitle2">
            Email <span style={{ color: "#e83030" }}>*</span>
          </Typography>
          <TextField
            sx={{ bgcolor: "#e8e8e8" }}
            variant="outlined"
            name={email}
            type="email"
            id={"email"}
            required
            placeholder="Your personal or active email"
            value={email}
            onChange={(e) => set_email(e.target.value)}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
          <Typography component="label" for={"name"} variant="subtitle2">
            Phone number <span style={{ color: "#e83030" }}>*</span>
          </Typography>
          {/* <PhoneInput
            specialLabel={''}
            required
            inputProps={{
              
              required: true,
            }}
          
            value={phone_number}
            onChange={set_phone_number} 
            inputClass="MuiInputBase-input MuiOutlinedInput-input css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input"
            containerClass="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl css-155vxsm-MuiInputBase-root-MuiOutlinedInput-root"
            style={{
border:"1px solid darkgray",
background:"#E8E8E8",


            }}
            rules={{ required: true }} /> */}

          <TextField
            sx={{ bgcolor: "#e8e8e8" }}
            variant="outlined"
            name={phone_number}
            id={"phone_number"}
            value={phone_number}
            type="tel"
            required
            placeholder="(123) 456-7890"
            // pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"

            onChange={(e) => {
              let cleaned = e.target.value.replace(/[^\d+]/g, '');
              // Handle backspace
              if (e.nativeEvent.inputType === 'deleteContentBackward') {
                cleaned = cleaned.slice(0, -1); // Remove the last digit
              }
              // Extract the three groups of digits: area code, prefix, and line number
              const areaCode = cleaned.substring(0, 3);
              const prefix = cleaned.substring(3, 6);
              const lineNumber = cleaned.substring(6);


              // Format the phone number using the desired pattern
              const formattedPhoneNumber = `(${areaCode}) ${prefix}-${lineNumber}`;

              if (formattedPhoneNumber.length <= 20)
                set_phone_number(formattedPhoneNumber);
            }
            }
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
        <Typography component="label" for={"name"} variant="subtitle2">
            Gender <span style={{ color: "#e83030" }}>*</span>
          </Typography>
        <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      sx={{
                        bgcolor: "#e8e8e8",
                        p: 2,
                        border: "1px solid #adadad",
                        borderRadius: 1,
                      }}
                      required
                      name="radio-buttons-group"
                    >
                      {['Male','Female'].map((option) => {
                        return (
                          <FormControlLabel
                            value={option}
                            required
                            sx={{ "&:hover": { bgcolor: "#e0e0e0" } }}
                            control={<Radio />}
                            label={option}
                            onClick={(event) => {
                             set_gender(event.target.value)
                            }}
                          />
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            bgcolor: "#e8e8e8",
            border: "1px solid #adadad",
            cursor: "pointer",
            mb: 2,
            p: 1.8,
          }}
          onClick={() =>
            set_have_you_been_to_chiropractic_services_before(
              !have_you_been_to_chiropractic_services_before
            )
          }
        >
          <Typography component="label" for={"name"} variant="subtitle2">
            Have you been to chiropractic services before?{" "}
            <span style={{ color: "#e83030" }}>*</span>
          </Typography>

          <Switch
            defaultChecked
            value={have_you_been_to_chiropractic_services_before}
            checked={have_you_been_to_chiropractic_services_before}

            onChange={(e) =>
              set_have_you_been_to_chiropractic_services_before(
                e.target.checked
              )
            }
            inputProps={{ "aria-label": "controlled" }}
          />
        </Box>

        {form_fields.map((input, index) => {
          return (
            <Box
              key={index}
              sx={{ display: "flex", alignItems: "center", mb: 2, width: user ? '98%' : "100%" }}
            >
              <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
                {input.type.toLowerCase() == "toggle" ? (
                  ""
                ) : (
                  <Typography
                    component="label"
                    for={input.label}
                    variant="subtitle2"
                    id="demo-radio-buttons-group-label"
                  >
                    {input.label}{" "}
                    {input.required ? (
                      <span style={{ color: "#e83030" }}>*</span>
                    ) : (
                      <span style={{ color: "darkgrey" }}>(Optional)</span>
                    )}
                  </Typography>
                )}
                {input.type.toLowerCase() == "radio" ? (
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      sx={{
                        bgcolor: "#e8e8e8",
                        p: 2,
                        border: "1px solid #adadad",
                        borderRadius: 1,
                      }}
                      required={input.required}
                      name="radio-buttons-group"
                    >
                      {input.value.map((option) => {
                        return (
                          <FormControlLabel
                            value={option}
                            required={input.required}
                            sx={{ "&:hover": { bgcolor: "#e0e0e0" } }}
                            control={<Radio />}
                            label={option}
                            onClick={(event) => {
                              const fields = [...form_fields];
                              fields[index]["answer"] = event.target.value;
                              set_form_fields(fields);
                            }}
                          />
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                ) : input.type.toLowerCase() == "toggle" ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      bgcolor: "#e8e8e8",
                      border: "1px solid #adadad",
                      cursor: "pointer",
                      mb: 2,
                      p: 1.8,
                    }}
                    onClick={() => {
                      const fields = [...form_fields];
                      fields[index]["value"] = !fields[index]["value"];
                      set_form_fields(fields);
                    }}
                  >
                    <Typography
                      component="label"
                      for={"name"}
                      variant="subtitle2"
                    >
                      {input.label}

                    </Typography>

                    <Switch
                      defaultChecked
                      value={input.value}
                      checked={input.value}

                      onChange={(e) => {
                        const fields = [...form_fields];
                        fields[index]["value"] = e.target.checked;
                        set_form_fields(fields);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Box>
                ) : (
                  <>
                    <TextField
                      sx={{ bgcolor: "#e8e8e8" }}
                      variant="outlined"
                      id={input.label}
                      name={input.variable_name}
                      value={input.value}
                      type={input.type}
                      onWheel={(e) => {
                        if (input.type == "number")
                          e.target.blur()
                      }}
                      inputMode={
                        input.type == "number"
                          ? "numeric"
                          : input.type == "email"
                            ? "email"
                            : input.type == "phone"
                              ? "tel"
                              : input.type == "url"
                                ? "url"
                                : "text"
                      }
                      required={input.required}
                      placeholder={input.placeholder}
                      onChange={(event) => handleInputChange(index, event)}
                    />
                  </>
                )}
              </Box>
              {user ? (
                <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "column", md: "row", lg: "row", xl: "row" }, mt: 2.75, alignItems: "center", justifyContent: "center" }}>
                  <Button
                    onClick={() => {
                      delete_field(index);
                    }}
                    fullWidth
                    sx={{
                      color: "#e83030",
                      borderRadius: 15,
                      height: "65px",
                      minWidth: "65px",
                    }}
                  >
                    <DeleteIcon />
                  </Button>
                  <Button
                    onClick={() => {
                      set_add_another_field_form(index);
                      set_type(input.type);
                      set_label(input.label);
                      set_variable_name(input.variable_name);
                      set_required(input.required);
                      set_placeholder(input.placeholder);
                    }}
                    variant="outlined"
                    fullWidth
                    color="info"
                    sx={{
                      borderRadius: 15,
                      height: "65px",
                      minWidth: "65px",
                    }}
                  >
                    <EditIcon />
                  </Button>
                </Box>
              ) : (
                ""
              )}
            </Box>
          );
        })}
        {alert ? (
          <Alert
            onClose={() => {
              set_alert(false);
            }}
            severity={alert.toLowerCase().includes("[error]") ? "error" : "success"}
            sx={{
              p: 2,
              width: 1,
              zIndex: 9923213,
              mx: -2,
              my: 2,
            }}
          >
            {alert}
          </Alert>
        ) : (
          ""
        )}
        {user ? (
          <Box sx={{ display: "flex" }}>
            <Button
              variant="outlined"
              fullWidth
              sx={{ minHeight: '43px', }}
              onClick={() => {
                set_add_another_field_form(true);
                set_type("");
                set_label("");
                set_variable_name("");
                set_required("");
                set_placeholder("");
              }}
            >
              Add another field
            </Button>{" "}
            <Button
              variant="contained"
              fullWidth
              sx={{ minHeight: '43px', }}
              onClick={() => {
                save_form_fields();
              }}
            >
              Save Fields
            </Button>
          </Box>
        ) : (
          <Button variant="contained" fullWidth type="submit" disabled={form_submitted ? true : false}>
            {form_submitted ? "Form submitted" : "Submit Form"}
          </Button>
        )}
      </Box>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  state: state,
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(PublicForm);
