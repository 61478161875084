import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, LinearProgress } from "@mui/material";
import { Alert, Box, Typography } from '@mui/material'

import { axios_post_call } from '../../../../components/AxiosCall';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';


const FacebookConnect = ({ user }) => {

  const [alert, set_alert] = useState(false);
  const [loading, set_loading] = useState(false);

  const navigate = useNavigate()
  const location = useLocation();
  const access_token = new URLSearchParams(location.hash.substring(1)).get("access_token");


  const connect_with_facebook = async () => {
    set_loading(true);

    let url = '/social-apps-integration/connect-with-facebook/';
    let res = await axios_post_call(url, { access_token }, set_loading);

    if (res.data.status == 1) {
      set_loading(false);
      set_alert(res.data.response);
    } else if (res.data.status == 0) {
      set_alert(res.data.response);
      set_loading(false);
    }
  }

  useEffect(() => {
    if (access_token)
      connect_with_facebook()
  }, [access_token])

  

  useEffect(() => {
    if (!(localStorage.getItem('access'))) {
      navigate('/login')
    }
  }, [])

  return (
    user ? <Box>

      <Box sx={{ minHeight: '80vh', display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Box>
          <Typography align="center" variant={loading ? "h1" : "h3"} marginTop={2}>
            {loading ? "Connecting with facebook" : alert}
          </Typography>

          {loading ? "" :
            <Box sx={{ display: 'flex', justifyContent: "center" }}>
              <Box my={2}>
                <Button variant='contained' onClick={() => { window.location.href = "/dashboard" }}>Visit Dashboard</Button>
              </Box>
            </Box>
          }

          <Box sx={{ width: "50vw", margin: "auto", mt: 2, }}>
            {loading ? <LinearProgress /> : ""}
          </Box>
        </Box>
      </Box>
    </Box> : ''
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(FacebookConnect);;