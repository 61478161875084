import * as XLSX from 'xlsx';

import { Calendar, dayjsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";


import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";


import Toolbar from "react-big-calendar/lib/Toolbar";
import dayjs from "dayjs";
import { useCallback } from "react";
import { connect } from "react-redux";
import { Box } from "@mui/system";
import { Alert, Button, CircularProgress, Typography } from "@mui/material";
import { axios_get_call } from "../../components/AxiosCall";
import { axios_post_call } from "../../components/AxiosCall";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRef } from 'react';
import generate_local_timezone_based_iso_date from "../../components/generate_local_timezone_based_iso_date";
import {CustomAgendaView} from "./CustomAgendaView.jsx";

const localizer = dayjsLocalizer(dayjs);
const DragAndDropCalendar = withDragAndDrop(Calendar);


const CalendarPage = ({ user }) => {
  const [posts, set_posts] = useState([]);
  const search = useLocation().search;
  const last_clicked_date_parameter = new URLSearchParams(search).get("last_clicked_date");
  const [last_clicked_date, set_last_clicked_date] = useState(last_clicked_date_parameter);

  const calendarRef = useRef(null);
  const navigate = useNavigate();
  const [loading, set_loading] = useState(false);
  const [alert, set_alert] = useState(false);
  const [is_superuser, set_is_superuser] = useState(false);
  const [csv, set_csv] = useState(false);

  function arrayToCsv(data) {
    const csvRows = [];

    // Get the headers
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(','));
    // Map the data to CSV rows
    data.forEach((row) => {
      const values = headers.map((header) => {

        return row[header];
      });
      csvRows.push(values.join(','));
    });

    // Join the CSV rows and return the CSV string
    return csvRows.join('\n');
  }

  function exportToExcel(data) {
    const fileName = "Marketing Platform Posts Data.xlsx";
    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const header = Object.keys(data[0]);

    const rows = data.map((row) =>
      header.map((fieldName) => row[fieldName].trim().replaceAll("_x00d_", "").replaceAll("\r", ""))
    );

    const worksheet = XLSX.utils.json_to_sheet([{ header }, ...rows], {
      skipHeader: true,
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], { type: contentType });

    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  const export_all_data_as_csv = async () => {
    let url = "/post-management/export-posts-as-csv/";
    set_csv("loading");
    let res = await axios_get_call(url, {}, set_loading, true);


    if (res.data.status == 1) {
      let data = res.data.response;
      exportToExcel(data)


      set_csv("exported");


    } else if (res.data.status == 0) {
      set_csv("failed");
      set_alert(res.data.response);
      set_loading(false);
    }
  };

  const get_posts_data = async () => {
    let url = "/post-management/get-posts/";
    let res = await axios_get_call(url, {}, set_loading);

    if (res.data.status == 1) {
      set_loading(false);
      set_posts(res.data.response);
    } else if (res.data.status == 0) {
      set_alert(res.data.response);
      set_loading(false);
    }
  };

  const handle_event_drop = async (e) => {
    let url = "/post-management/update-post-date/";
    let res = await axios_post_call(url, { post_id: e.event.id, date: generate_local_timezone_based_iso_date(e.start) });


    if (res.data.status == 0) {
      set_alert(res.data.response);
      set_loading(false);
    } else {
      let all_posts = JSON.parse(JSON.stringify(posts))
      const to_updated_post_index = all_posts.findIndex(post => post.id === e.event.id);
      if (to_updated_post_index !== -1) {
        let date = generate_local_timezone_based_iso_date(e.start)
        all_posts[to_updated_post_index]['start'] = date
        all_posts[to_updated_post_index]['end'] = date
        set_posts(all_posts);


      } else {
        // Dictionary with the specified ID is not found
        console.log("Post not found")
        window.location.reload()
      }
    }



  };


  const handle_slot_onclick = useCallback((event) => {
    const parsed_date = generate_local_timezone_based_iso_date(new Date(event.start))
    navigate(
      `/post/?view_post_id=${event.id}&upload_date=${parsed_date}&to_edit=${false}`
    );
  }, []);


  const select_a_slot = useCallback((event) => {
    const parsed_date = generate_local_timezone_based_iso_date(event)

    navigate(
      `/post/?view_post_id=${false}&upload_date=${parsed_date}&to_edit=${true}`
    );
  }, []);

  useEffect(() => {
    get_posts_data();
  }, []);

  useEffect(() => {
    if (!window.localStorage.getItem("access")) {
      navigate("/login")
    }
  }, [])

  useEffect(() => {
    if (user) {
      if (user.is_superuser) {
        set_is_superuser(true);
      }
      if (user)
        if (user.location_name == null) navigate("/complete-profile");
        else if (user.name == null) navigate("/complete-profile");

        else if (user.location_phone == null) navigate("/complete-profile");
        else if (user.location_page_url == null) navigate("/complete-profile");
        else if (user.location_address == null) navigate("/complete-profile");
        else if (user.location_city_or_state == null)
          navigate("/complete-profile");
    }
  }, [user]);

  const show_title = (e) => {
    return <Typography sx={{ display: "flex", alignItems: "center" }}><img src={e.icon_url ? e.icon_url : ""} style={{ marginRight: '4px', marginLeft: '2px' }} width={'14px'} /> {e.title}</Typography>
  };



  const eventStyleGetter = (e, start, end, isSelected) => {
    let backgroundColor = isSelected ? e.style.backgroundColor : e.color;


    let style = {
      backgroundColor: backgroundColor,
      borderRadius: '0px',
      opacity: 0.8,
      color: 'white',
      border: '0px',
    };
    e.style = style
    return e
  }
  var CustomToolbar = () => {
    return class BaseToolBar extends Toolbar {
      constructor(props) {
        super(props);
      }
      handleDayChange = (value, mconte) => {
        mconte(value);
      };
      handleNavigate = (detail, elem) => {
        detail.navigate(elem);
      };
      render() {
        return (
          <Box sx={{ display: 'flex', flexDirection: { xl: "row", lg: "row", md: "row", sm: "column", xs: "column" }, justifyContent: 'space-between', alignItems: "center", textAlign: 'center' }}>
            <Box sx={{ flex: 1, textAlign: 'left' }} className="rbc-btn-group">
              <Button
                type="button"
                variant='outlined'
                onClick={() => this.handleNavigate(this, "TODAY")}
              >
                Today
              </Button>
              <Button
                type="button"
                variant='outlined'
                onClick={() => this.handleNavigate(this, "PREV")}
              >
                Prev
              </Button>
              <Button
                type="button"
                variant='outlined'
                onClick={() => this.handleNavigate(this, "NEXT")}
              >
                Next
              </Button>
            </Box>
            <Box sx={{ flex: 1, my: 2 }} className="rbc-toolbar-label">{this.props.label}</Box>

            <Box sx={{ flex: 1, textAlign: 'right', mb: 2 }} className="rbc-btn-group">
              <Button
                type="button"
                variant='outlined'
                onClick={() => this.handleDayChange('month', this.view)}
                sx={{ p: { xs: "8px", sm: "5px 15px" }, fontSize: { xs: '12px', sm: "0.875rem" }, minWidth: { xs: 'fit-content', sm: "64px" } }} >
                Month
              </Button>
              <Button
                type="button"
                variant='outlined'
                onClick={() => this.handleDayChange('week', this.view)}
                sx={{ p: { xs: "8px", sm: "5px 15px" }, fontSize: { xs: '12px', sm: "0.875rem" }, minWidth: { xs: 'fit-content', sm: "64px" } }}  >
                Week
              </Button>
              <Button
                type="button"
                variant='outlined'
                onClick={() => this.handleDayChange('day', this.view)}
                sx={{ p: { xs: "8px", sm: "5px 15px" }, fontSize: { xs: '12px', sm: "0.875rem" }, minWidth: { xs: 'fit-content', sm: "64px" } }} >
                Day
              </Button>
              <Button
                type="button"
                variant='outlined'
                onClick={() => this.handleDayChange('agenda', this.view)}
                sx={{ p: { xs: "8px", sm: "5px 15px" }, fontSize: { xs: '12px', sm: "0.875rem" }, minWidth: { xs: 'fit-content', sm: "64px" } }}
              >
                Agenda
              </Button>
            </Box>

          </Box>
        );
      }
    };
  };



  return (
    <Box sx={{ width: "95%", m: "auto" }}>

      <Typography variant="h1" align="center" sx={{ p: 2 }}>
        Marketing Calendar
      </Typography>
      <Typography variant="subtitle2" align="center">
        Click on each post to see it's contents.
      </Typography>
      {/* {is_superuser ? (
        <Typography variant="subtitle2" align="center" sx={{ pb: 4 }}>
          You are admin user so you can click on any slot to add a post at that
          date.
        </Typography>
      ) : (
        ""
      )} */}

      <Box sx={{ mt: 4, mb: 2, display: "flex", justifyContent: "right" }}>
        <Button variant={is_superuser ? "outlined" : "contained"} onClick={() => {
          if (csv == false)
            export_all_data_as_csv()

        }}>
          {csv == "loading" ? <CircularProgress color={is_superuser ? "primary" : "secondary"} /> : csv == "exported" ? "Exported" : csv == "failed" ? "Failed to export" : "Export Excel"}
        </Button>
        {is_superuser ?
          <Button variant="contained" onClick={() => {
            const parsed_date = generate_local_timezone_based_iso_date(new Date())
            navigate(
              `/post/?view_post_id=${false}&upload_date=${parsed_date}&to_edit=${true}`
            );
          }}>
            New post
          </Button>
          : ""}
      </Box>
      <Box sx={{ boxShadow: 12, p: 4, mb: 6, borderRadius: 1, }} >
        {loading ? (
          <CircularProgress />
        ) : (
          <DragAndDropCalendar
            ref={calendarRef}
            
          
            localizer={localizer}
            events={posts}
            startAccessor="start"
            onEventDrop={handle_event_drop}
            onSelectEvent={handle_slot_onclick}
            onSelectSlot={(e) => {
              if (is_superuser) select_a_slot(e.start);
            }}
            endAccessor="end"
            defaultDate={last_clicked_date ? new Date(last_clicked_date) : new Date()}
            enableAutoScroll
            selectable
            dayLayoutAlgorithm="no-overlap"

            components={{
              toolbar: CustomToolbar()
            }}

            popup
            eventPropGetter={eventStyleGetter}
            titleAccessor={function (e) {
              return show_title(e);
            }}

            views={{
              week: true,
              month: true,
              day: true,
              agenda: true
            }}

          />
        )}  {alert ? (
          <Alert
            onClose={() => {
              set_alert(false);
            }}
            severity="error"
            sx={{
              p: 2,
              width: 1,
              zIndex: 9923213,
            }}
          >
            {alert}
          </Alert>
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
};



const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(CalendarPage);
