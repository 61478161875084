import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  signup,
  facebookAuthenticate,
  googleAuthenticate,
} from "../../actions/auth";
import axios from "axios";
import { gapi } from "gapi-script";

// UI elements
import {
  TextField,
  Button,
  Box,
  Typography,
  CircularProgress,
  InputLabel,
  NativeSelect,
  FormControl,
  Boxider,
  Alert,
} from "@mui/material";
import { alert_box } from "../../components/alert_structure";
import csrf_grabber from "../../components/csrf_grabber";

const Signup = ({
  signup,
  isAuthenticated,
  facebookAuthenticate,
  googleAuthenticate,
}) => {
  const search = useLocation().search;
  const username_from_url = new URLSearchParams(search).get("username");

  const [formData, setFormData] = useState({
    username: username_from_url ? username_from_url : "",
    language: "en",
    email: "",
    password1: "",
    password2: "",
  });
  var csrftoken = csrf_grabber("csrftoken");

  const { username, language, email, password1, password2 } = formData;
  const [successful, set_successful] = useState(false);
  const [username_already_exists, set_username_already_exists] =
    useState("not set");
  const timeout = useRef(null);
  const [loading, set_loading] = useState(false);
  const navigate = useNavigate();
  const [alert, set_alert] = useState(false);


  const onChange = (e) => {
    // let value = "";
    // if (e.target.name == "username") {
    //   value = e.target.value.toLowerCase();
    //   if (value != "" && value.length > 2) {
    //     set_username_already_exists("loading");
    //     clearTimeout(timeout.current);
    //     timeout.current = setTimeout(async () => {
    //       let config = {
    //         params: { username: value },
    //         headers: {
    //           "X-CSRFToken": csrftoken,
    //           "Content-Type": "application/json",
    //           Accept: "application/json",
    //         },
    //       };
    //       let url = `${process.env.REACT_APP_BACKEND_URL}/auth/check-if-username-is-available/`;
    //       let res_data = await axios.get(url, config);
    //       if (res_data.data == 1) {
    //         set_username_already_exists(true);
    //       } else if (res_data.data == 0.5) {
    //         set_username_already_exists("not allowed");
    //       }else{

    //         set_username_already_exists(false);
    //       }
    //     }, 1125);
    //   }else{
    //     set_username_already_exists("not set");
    //   }
    // } else if (e.target.name == "email") {
    //   value = e.target.value.toLowerCase();
    // } else {
    // }
    let value = e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
  };


  const onSubmit = async (e) => {
    e.preventDefault();
    // if (username_already_exists === false){
    if (password1 === password2) {
      // if (username.length > 2) {
      set_loading(true);
      let sign_up_res = await signup(
        // username,
        language,
        email,
        password1,
        password2,
        set_alert
      ).then();
      set_loading(false);
      if (sign_up_res == 1) {
        set_successful(true);
      }
      // } else {
      //   let html = alert_box(
      //     "Username should be minimum 3 characters",
      //     "alertBoxError"
      //   );
      // let AlertManager = document.getElementById("AlertsManager");
      // AlertManager.innerHTML += html;
      // }
    } else {
      set_alert("Both passwords must match");
    }
    // }else{
    //     let html = alert_box("Select a different and unique username.", "alertBoxError");
    //     let AlertManager = document.getElementById("AlertsManager");
    //     AlertManager.innerHTML += html;
    //   }
  };

  useEffect(() => {
    const initClient = () => {
      gapi.auth2.init({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: "email profile",
        fetch_basic_profile: false,
      });
    };
    gapi.load("client:auth2", initClient);
  });

  const continueWithGoogle = (res) => {
    googleAuthenticate(res.accessToken);
  };

  const continueWithFacebook = (res) => {
    facebookAuthenticate(res.accessToken);
  };

  if (isAuthenticated) {
    setTimeout(async () => {
      navigate("/calendar");
    }, 3000);
  }

  return (
    <Box className="container">
      {successful ? (
        <Box
          sx={{
            zIndex: 2,
            position: "absolute",
            minWidth: "100%",
            minHeight: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              minWidth: "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "20vh",
              mb: 20,
              background: "white",
              borderRadius: 5,
              boxShadow: 5,
              p:4
            }}
          >
            <Typography align="center" fontSize="xx-large">
              Account created successfully. Logging in...
            </Typography>

            <br />
            <Button
              sx={{ p: 0.25 }}
              onClick={() => set_successful(false)}
              variant="contained"
            >
              Okay
            </Button>
          </Box>
        </Box>
      ) : (
        ""
      )}
      <Box
        style={{
          display: "inline-flex",
          flexDirection: "column",
          minHeight: "100vh",
          maxHeight: "100%",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h1" style={{ margin: 0 }}>Sign Up</Typography>
        <Typography variant="subtitle2">Create your Account</Typography>
        <br />
      
        <Box
          style={{
            display: "flex",
            "flex-direction": "column",
            alignItems: "center",
          }}
        >
        <Box
            component={"form"}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              minWidth: { xs: "100%", sm: "100%", md: "350px" },
              m: "auto",
            }}
            onSubmit={(e) => onSubmit(e)}
          >
            {/* username Field */}
            {/* <TextField
              id="outlined-basic"
              label="Username"
              variant="outlined"
              margin="dense"
              size="small"
              type="text"
              name="username"
              inputProps={{ maxLength: 255 }}
              value={username}
              onChange={(e) => onChange(e)}
              required
            />
            <Typography
              align="center"
              variant="subtitle1"
              sx={{
                p: 0.5,
                borderRadius: 5,
                mb: 2,
                bgcolor: username_already_exists
                  ? username_already_exists == "loading"
                    ? "initial"
                    : username_already_exists == "not set"
                    ? username_already_exists == "not allowed"? "#ffb5b5" :"lightgrey"
                    : "#ffb5b5"
                  : "lightgreen",
              }}
            >
              {username_already_exists ? (
                username_already_exists == "loading" ? (
                  <CircularProgress sx={{ fontSize: "small" }} />
                ) : username_already_exists == "not set" ? (
                  "Usernames are unique"
                ) : username_already_exists == "not allowed" ? "Username not allowed" : (
                  "Already taken"
                )
              ) : (
                "Available"
              )}
            </Typography> */}

            {/* Language Field  */}
            {/* <FormControl fullWidth>
  <NativeSelect
  sx={{mt:2, mb:2, border: 1, p:1}}
    defaultValue={"en"}
    
    value={language}
    onChange={(e) => onChange(e)}

    inputProps={{
      name: 'Language',
      id: 'uncontrolled-native',
    }}
  >

    <option value={"en"}>English</option>
    <option value={"es"}>Spanish</option>
  </NativeSelect>
</FormControl> */}
            {/* Email Field */}
            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              margin="dense"
              size="small"
              type="email"
              name="email"
              inputProps={{ maxLength: 255 }}
              value={email}
              onChange={(e) => onChange(e)}
              required
            />
            {/* Password Field */}
            <TextField
              id="outlined-basic"
              label="Password"
              variant="outlined"
              margin="dense"
              size="small"
              type="password"
              name="password1"
              value={password1}
              onChange={(e) => onChange(e)}
              minLength="6"
              required
            />
            {/* Confirm Password Field */}
            <TextField
              id="outlined-basic"
              label="Confirm Password"
              variant="outlined"
              margin="dense"
              size="small"
              type="password"
              name="password2"
              value={password2}
              onChange={(e) => onChange(e)}
              minLength="6"
              required
            />
              {alert ? (
        <Alert
          onClose={() => {
            set_alert(false);
          }}
          severity={alert.toLowerCase().includes("[error]") ? "error" : "success"}
          sx={{
            
            m:"auto",
            mb: 2,
            maxWidth:"350px",
            zIndex: 9923213,
          }}
        >
          {alert}
        </Alert>
        ):""}
            <Button
              sx={{ margin: "4px", width: "96%" }}
              variant="contained"
              type="submit"
            >
              {loading ? <CircularProgress color="secondary" /> : "Register"}
            </Button>
          </Box>

          <br />
          {/* <Boxider flexItem>
              or register with
            </Boxider>
          <br />
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "140%",
            }}
          >
            <Box style={{ flex: 1 }}>
              <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                onSuccess={continueWithGoogle}
                onFailure={continueWithGoogle}
                redirect_uri={`${process.env.REACT_APP_FRONTEND_URL}/google`}
                cookiePolicy={"single_host_origin"}
                render={renderProps => (
                  <Button variant= "outlined" fullWidth onClick={renderProps.onClick} disabled={renderProps.disabled}>Continue with Google</Button>
                )}
              />
            </Box>
            <Box sx={{ minWidth: "100%" }}>
              <FacebookLogin
                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                fields="email, username"
                callback={continueWithFacebook}
                render={renderProps => (
                  <Button variant= "outlined" fullWidth onClick={renderProps.onClick}>Continue with Facebook</Button>
                )}
              />
            </Box>
          </Box> */}
        </Box>
        <br />
        <Typography variant="subtitle2">
          Already have an account?{" "}
          <Link
            to="/login"
          >
            Log In
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {
  signup,
  facebookAuthenticate,
  googleAuthenticate,
})(Signup);
