import React, { useRef } from "react";

import { useDropzone } from "react-dropzone";
import { LazyVideo, LazyImage } from "react-lazy-media";
import { connect } from "react-redux";
import { Box } from "@mui/system";
import {
  Alert,
  Button,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

import TextField from "@mui/material/TextField";

import generate_local_timezone_based_iso_date from "../../components/generate_local_timezone_based_iso_date";
import {
  axios_delete_call,
  axios_get_call,
  axios_post_call,
  axios_put_call,
} from "../../components/AxiosCall";
import CustomModal from "../../components/CustomModal";
import { useEffect } from "react";
import { useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import LeftIcon from '@mui/icons-material/ChevronLeft';
import RightIcon from '@mui/icons-material/ChevronRight';
function ViewOrCreatePost({ user }) {
  const navigate = useNavigate();

  const [loading, set_loading] = useState(false);
  const [alert, set_alert] = useState(false);
  const [is_superuser, set_is_superuser] = useState(false);

  const title_input_ref = useRef();
  const description_input_ref = useRef();
  const [cursorPosition, setCursorPosition] = useState(0);

  const search = useLocation().search;
  const upload_date = new URLSearchParams(search).get("upload_date");
  let to_edit = new URLSearchParams(search).get("to_edit");
  const view_post_id = new URLSearchParams(search).get("view_post_id");
  const [previous_post_details, set_previous_post_details] = useState(false)
  const [next_post_details, set_next_post_details] = useState(false)
  const [to_edit_or_create, set_to_edit_or_create] = useState(
    to_edit.toLowerCase() === "true"
  );
  const [post_upload_date, set_post_upload_date] = useState(upload_date);
  const [is_view_post, set_is_view_post] = useState(view_post_id);
  const [view_post_data, set_view_post_data] = useState({});
  const [is_copied, set_is_copied] = useState(false);
  const [to_delete_warning, set_to_delete_warning] = useState(false);
  const [to_delete, set_to_delete] = useState(false);

  const [platforms, set_platforms] = useState([]);
  const [post_image, set_post_image] = useState("");
  const [post_image_prev, set_post_image_prev] = useState("");
  const [post_image_prev_loading, set_post_image_prev_loading] =
    useState(false);
  const [post_platform, set_post_platform] = useState("Facebook");
  const [post_title, set_post_title] = useState("");
  const [post_body, set_post_body] = useState("");
  const [image_or_video, set_image_or_video] = useState("");

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({

    onDropAccepted: (files) => {
      const file = files[0];
      set_post_image(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      set_post_image_prev_loading(true);

      reader.onload = () => {
        if (file.type.includes("video")) {
          set_image_or_video("video");
        } else {
          set_image_or_video("image");
        }

        const dataURL = reader.result;
        set_post_image_prev(dataURL);
        set_post_image_prev_loading(false);
      };
    },


    accept: {
      'image/jpeg': [],
      'image/jpg': [],
      'image/png': [],
      'image/gif': [],
      'video/avi': [],
      'video/mp4': []
    }

  });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const variables = [
    "$email",
    "$location_name",
    "$location_phone",
    "$location_page_url",
    "$location_address",
    "$location_city_or_state",
  ];

  const delete_post = async () => {
    let url = "/post-management/delete-post/";
    let res = await axios_delete_call(url, { post_id: to_delete }, set_loading);
    set_loading(false);

    if (res.data.status == 1) {
      set_alert(res.data.response);
      set_to_delete_warning(false);
      set_to_delete(false);
      const parsed_date = generate_local_timezone_based_iso_date(new Date(post_upload_date))
      let url = "/calendar?last_clicked_date=" + parsed_date
      navigate(url);
    } else if (res.data.status == 0) {
      set_alert(res.data.response);
      set_to_delete_warning(false);
      set_to_delete(false);
    }
  };
  useEffect(() => {
    if (!window.localStorage.getItem("access")) {
      navigate("/login")
    }

  }, [])
  const create_or_update_post = async () => {
    set_loading(true);
    let url = "/post-management/create-post/";
    let body = new FormData();
    try {
      body.append("post_image", post_image, post_image.name);
    } catch (error) {
      body.append("post_image", "none");
    }
    body.append("post_platform", post_platform);
    body.append("post_title", post_title);
    body.append("post_body", post_body);
    body.append("post_upload_date", post_upload_date);
    let res = "";

    if (typeof to_edit_or_create == "boolean") {
      body.append("request_type", 'POST');
      res = await axios_post_call(url, body, set_loading, "multipart/form-data");
    } else {
      body.append("request_type", 'PUT');
      body.append("post_id", to_edit_or_create);
      res = await axios_post_call(url, body, set_loading, "multipart/form-data");
    }

    if (res.data.status == 1) {
      set_alert(res.data.response);
      set_loading(false);
      set_to_edit_or_create("not editing");
      set_is_view_post(res.data.id);


      if (typeof to_edit_or_create == "boolean") {
        set_alert("Post uploaded, page will reload to reflect the changes!");

        // Reload the page with the new URL
        const new_url = JSON.parse(JSON.stringify(window.location.href));
        const parsed_date = generate_local_timezone_based_iso_date(post_upload_date)
        const processed_url = new_url.split("upload_date=")[0] + "upload_date=" + parsed_date + "&" + new_url.split("upload_date=")[1].split("&")[1]

        window.location.href = processed_url.replaceAll("false", res.data.id).replaceAll("true", "false");

      } else {
        await get_post_data(res.data.id);
      }
    } else if (res.data.status == 0) {
      set_alert(res.data.response);
      set_loading(false);
    } else {
      set_alert(res.data.detail);
      set_loading(false);
    }
  };

  const get_platforms = async () => {
    let url = "/overview/get-platforms/";
    let res = await axios_get_call(url, {}, set_loading);

    if (res.data.status == 1) {
      set_loading(false);
      set_platforms(res.data.response);
    } else if (res.data.status == 0) {
      set_alert(res.data.response);
      set_loading(false);
    }
  };

  const get_post_data = async (post_id) => {
    let url = "/post-management/get-post/";
    let res = await axios_get_call(url, { post_id: post_id }, set_loading);

    if (res.data.status == 1) {
      set_loading(false);
      set_view_post_data(res.data.response);
      set_image_or_video(res.data.response.image_file_type);
      set_previous_post_details(res.data.response.previous_post_details);
      set_next_post_details(res.data.response.next_post_details);


    } else if (res.data.status == 0) {
      set_alert(res.data.response);
      set_loading(false);
    }
  };

  const download_image = (image_url, image_title) => {
    if (image_or_video == "video") {
      window.open(image_url, "_blank")
    } else {
      fetch(`${process.env.REACT_APP_BACKEND_URL}${'/post-management/download-media/'}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ image_url }),

        method: 'POST',


      }).then((response) => response.blob())
        .then((blob) => {
          var urlCreator = window.URL || window.webkitURL;

          const url = urlCreator.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = image_title;
          a.target = '_blank';

          a.click();
          urlCreator.revokeObjectURL(url);

          set_is_copied(false);
        });
    }
  };

  useEffect(() => {
    if (to_delete) {
      delete_post();
    }
  }, [to_delete]);

  useEffect(() => {
    get_platforms();
  }, []);

  useEffect(() => {
    if (is_view_post && to_edit_or_create === false) {
      get_post_data(is_view_post);
    }
  }, []);
  useEffect(() => {
    if (user) {
      if (user.is_superuser) {
        set_is_superuser(true);
      }

      if (user.location_name == null) navigate("/complete-profile");
      else if (user.name == null) navigate("/complete-profile");

      else if (user.location_phone == null) navigate("/complete-profile");
      else if (user.location_page_url == null) navigate("/complete-profile");
      else if (user.location_address == null) navigate("/complete-profile");
      else if (user.location_city_or_state == null)
        navigate("/complete-profile");

    }
  }, [user]);

  useEffect(() => {
    if (to_edit_or_create == false) {
      if (to_edit.toLowerCase() === "true" && !view_post_data.title) {
        const parsed_date = generate_local_timezone_based_iso_date(new Date(post_upload_date))
        let url = "/calendar?last_clicked_date=" + parsed_date
        navigate(url);
      }
    }
  }, [to_edit_or_create]);


  return (
    <Box sx={{ m: 2 }}>
      {alert ? (
        <Alert
          onClose={() => {
            set_alert(false);
          }}
          severity={alert.toLowerCase().includes("[error]") ? "error" : "success"}
          sx={{
            p: 2, width: '88%',
            zIndex: 9923213,
          }}
        >
          {alert}
        </Alert>
      ) : (
        ""
      )}

      {to_edit_or_create && to_edit_or_create != "not editing" ? (
        <CustomModal set_preview={set_to_edit_or_create}>
          <Box
            sx={{
              width: { xl: "35%", lg: "50%", md: "60%", sm: "80%", xs: "90%" },
              h: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              bgcolor: "white",
              cursor: "auto",
              borderRadius: 3,
              boxShadow: 24,
              p: { xl: 4, lg: 4, md: 4, sm: 4, xs: 2 },
            }}
          >
            {alert ? (
              <Alert
                onClose={() => {
                  set_alert(false);
                }}
                severity={alert.toLowerCase().includes("[error]") ? "error" : "success"}
                sx={{
                  p: 2,
                  width: '88%',
                  zIndex: 9923213,

                }}
              >
                {alert}
              </Alert>
            ) : (
              ""
            )}

            <style
              dangerouslySetInnerHTML={{
                __html: "\n#menu-{\nz-index:9923214}\n",
              }}
            />
            <Typography variant="h2">
              {typeof to_edit_or_create == "boolean"
                ? "Create a new post"
                : "Update the post"}
            </Typography>
            <Box sx={{ my: 2, width: 1 }} component="form">
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ mb: 3, flex: 1 }}>
                  <Typography variant="subtitle2" align="left">
                    Upload Date
                  </Typography>
                  <TextField
                    required
                    type="date"
                    value={post_upload_date}
                    onChange={(e) => {
                      set_post_upload_date(e.target.value);
                    }}
                    fullWidth
                  />
                </Box>
                <Box sx={{ mb: 3, flex: 1 }}>
                  <Typography variant="subtitle2" align="left">
                    Platform
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={post_platform}
                      onChange={(e) => {
                        set_post_platform(e.target.value);
                      }}
                    >
                      {platforms.map((platform) => (
                        <MenuItem value={platform.name}>
                          {platform.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              <Box sx={{ mb: 3 }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Typography variant="subtitle2" align="left">
                      Title
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <FormControl>
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        renderValue={(selected) => {
                          return <em>{selected}</em>;
                        }}
                        value="Variables"
                        onChange={(e) => {
                          set_post_title(
                            post_title.slice(0, cursorPosition) +
                            ` ${e.target.value} ` +
                            post_title.slice(cursorPosition)
                          );
                          setCursorPosition(
                            cursorPosition + e.target.value.length + 1
                          );
                        }}
                      >
                        {variables.map((variable) => (
                          <MenuItem value={variable}>{variable}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <TextField
                  id="title_input"
                  required
                  multiline
                  rows={2}
                  value={post_title}
                  onChange={(e) => {
                    set_post_title(e.target.value);
                  }}
                  fullWidth
                  ref={title_input_ref}
                  onBlur={() => {
                    setCursorPosition(
                      title_input_ref.current.childNodes[0].children[
                        "title_input"
                      ].selectionStart
                    );
                  }}
                />
              </Box>
              <Box sx={{ mb: 3 }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Typography variant="subtitle2" align="left">
                      Description
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <FormControl fullWidth>
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        renderValue={(selected) => {
                          return <em>{selected}</em>;
                        }}
                        value="Variables"
                        onChange={(e) => {
                          set_post_body(
                            post_body.slice(0, cursorPosition) +
                            ` ${e.target.value} ` +
                            post_body.slice(cursorPosition)
                          );
                          setCursorPosition(
                            cursorPosition + e.target.value.length + 1
                          );
                        }}
                      >
                        {variables.map((variable) => (
                          <MenuItem value={variable}>{variable}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <TextField
                  required
                  multiline
                  inputProps={{
                    style: {
                      resize: "vertical",
                    },
                  }}
                  minRows={10}
                  value={post_body}
                  onChange={(e) => {
                    set_post_body(e.target.value);
                  }}
                  fullWidth
                  ref={description_input_ref}
                  onBlur={() => {
                    setCursorPosition(
                      description_input_ref.current.childNodes[0].children[
                        "description_input"
                      ].selectionStart
                    );
                  }}
                  id="description_input"
                  resizable
                />
              </Box>

              <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle2" align="left">
                  Image/Thumbnail
                </Typography>
                {post_image_prev_loading ? (
                  <CircularProgress />
                ) : post_image_prev ? (
                  <Box
                    sx={{
                      position: "relative",
                      maxWidth: "615px",
                      minHeight: "200px",
                      overflow: "hidden",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Box>
                      <Box
                        sx={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          opacity: 1,
                          zIndex: 110,
                          m: 1,
                          cursor: "pointer",
                        }}
                      >
                        <Button
                          sx={{
                            borderRadius: "100%",
                            bgcolor: "#e8e8e8",
                            boxShadow: 3,
                            bgcolor: "error.main",
                            color: "white",
                            "&:hover": { bgcolor: "white", color: "black", },
                            minWidth: '70px',
                            height: '70px',
                          }}
                          variant="gray_button"
                          onClick={() => {
                            set_post_image("");
                            set_post_image_prev("");
                            set_post_image_prev_loading(false);
                          }}
                        >
                          <DeleteIcon sx={{ fontSize: 26 }} />
                        </Button>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "1px solid lightgrey",

                          maxWidth: "450px",
                          maxHeight: "1024px",
                          overflow: "hidden",
                        }}
                      >
                        {image_or_video == "video" ? (
                          <Box sx={{ width: "100%" }}>

                            <video
                              autoPlay={false}
                              preload="none"
                              poster={
                                "https://marketing.100percentfranchise.com/media/click_to_load_the_video.png"
                              }
                              src={post_image_prev}
                              muted
                              controls
                              onClick={(e) => e.preventDefault()}
                              loading={"lazy"}
                              style={{ width: '100%' }}
                            ></video></Box>
                        ) : (

                          <img src={post_image_prev} width={"100%"} />
                        )}
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <section className="container">
                    <Box
                      {...getRootProps({ className: "dropzone" })}
                      sx={{
                        cursor: "pointer",
                        border: "2px lightgrey dashed",
                        p: 2,
                      }}
                    >
                      <input
                        type="file"
                        {...getInputProps()}
                        maxFiles={1}
                        multiple={false}
                      />
                      <Typography variant="subtitle2">
                        Drag and drop the image/video here or click
                      </Typography>
                    </Box>
                  </section>
                )}
              </Box>
            </Box>
            <Box sx={{ width: 1 }}>
              <Button
                variant="contained"
                fullWidth

                sx={{ minHeight: '43px', width: 1 }}
                onClick={() => {
                  create_or_update_post();
                }}
              >
                {loading ? (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    Uploading{" "}
                    <CircularProgress
                      size={25}
                      color="secondary"
                      sx={{ ml: 1 }}
                    />
                  </Box>
                ) : typeof to_edit_or_create == "boolean" ? (
                  "Create"
                ) : (
                  "Update"
                )}
              </Button>

              <Button
                variant="gray_button"
                fullWidth
                sx={{ minHeight: '43px', mt: 1 }}
                onClick={() => {
                  set_to_edit_or_create(false);
                }}
              >
                Cancel
              </Button>
            </Box>
            {loading ? (
              <Typography sx={{ mt: 2 }} variant="subtitle2">
                This will take some time, please don't close this window!
              </Typography>
            ) : (
              ""
            )}
            {alert ? (
              <Alert
                onClose={() => {
                  set_alert(false);
                }}
                severity={alert.toLowerCase().includes("[error]") ? "error" : "success"}
                sx={{
                  p: 2, width: '88%',
                  zIndex: 9923213,
                }}
              >
                {alert}
              </Alert>
            ) : (
              ""
            )}
          </Box>
        </CustomModal>
      ) : (
        ""
      )}
      {to_delete_warning ? (
        <CustomModal set_preview={set_to_delete_warning}>
          <Box
            sx={{
              width: { xl: "27%", lg: "35%", md: "40%", sm: "50%", xs: "100%" },
              h: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              bgcolor: "white",
              cursor: "auto",
              borderRadius: 3,
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography variant="subtitle2">
              Are you sure you want to delete it?
            </Typography>
            <Box>
              <Button
                variant="contained"
                onClick={() => {
                  set_to_delete(to_delete_warning);
                }}
              >
                Yes
              </Button>
              <Button
                variant="gray_button"
                onClick={() => {
                  set_to_delete_warning(false);
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </CustomModal>
      ) : (
        ""
      )}

      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: { xs: "100%", sm: "100%", md: "615px" }, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ flex: 1, textAlign: "left", display: 'flex', alignItems: 'center', justifyContent: 'left', }}>
              <Tooltip title={previous_post_details ? "Previous post" : "No previous posts"}>
                <Box sx={{ bgcolor: "#eaeaea", width: '35px', height: '35px', p: 1, borderRadius: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: "pointer", opacity: previous_post_details ? 1 : 0.25  }} onClick={() => {
                  if (previous_post_details) {
                    let url = `/post/?view_post_id=${previous_post_details.id}&upload_date=${generate_local_timezone_based_iso_date(previous_post_details.uploaded_date)}&to_edit=false`
                    window.location.href = url
                  }
                }}>
                  <LeftIcon sx={{ mx: -1.35, p: 0, fontSize: 30 }} />

                </Box>
              </Tooltip>
            </Box>
            <Box sx={{ flex: 2, }}><Typography variant="h1" sx={{ color: "grey", mt: 2, }}>
              <strong>Post for: </strong>
              {view_post_data.uploaded_date}
            </Typography></Box>
            <Box sx={{ flex: 1, textAlign: "right", display: 'flex', alignItems: 'center', justifyContent: 'right', }}>
              <Tooltip title={next_post_details ? "Next post" : "No further posts"}>
                <Box sx={{ bgcolor: "#eaeaea", width: '35px', height: '35px', p: 1, borderRadius: 12, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: "pointer", opacity: next_post_details ? 1 : 0.25 }} onClick={() => {
                  if (next_post_details) {
                    let url = `/post/?view_post_id=${next_post_details.id}&upload_date=${generate_local_timezone_based_iso_date(next_post_details.uploaded_date)}&to_edit=false`
                    window.location.href = url
                  }
                }}>
                  <RightIcon sx={{ mx: 1.35, p: 0, fontSize: 30 }} />
                </Box>
              </Tooltip>
            </Box>
          </Box>


          <Chip
            variant="filled"
            label={view_post_data.platform}
            avatar={
              <Box
                sx={{ display: "flex", alignItems: "center", maxWidth: "14px", px: '3px' }}
              >
                <img
                  alt={view_post_data.platform}
                  src={view_post_data.platform_icon}
                  style={{ marginRight: "4px", marginLeft: "2px" }}
                  width={"100%"}
                />
              </Box>
            }
            sx={{
              mt: 1,
              mb: 4,
              bgcolor: view_post_data.platform_color,
              color: "white",
              fontWeight: "bold",
            }}
          />
        </Box>
        <Box
          sx={{
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            border: "1px solid lightgrey",
          }}
        >
          <br />

          <Box sx={{ maxWidth: "615px", width: { xs: "100%", sm: "100%", md: "615px" } }}>
            {/* Title */}
            <Box sx={{ position: "relative", my: 4 }}>
              {view_post_data.title ? view_post_data.title.length > 0 ? <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  bgcolor: is_copied == "title" ? "#4aad4a" : "primary.main",
                  color: "White",
                  boxShadow: 4,
                  border: "5px solid white",
                  p: 1,
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.navigator.clipboard.writeText(view_post_data.title);
                  set_is_copied("title");
                  setTimeout(() => {
                    set_is_copied(false);
                  }, 1000);
                }}
              >
                <ContentCopyIcon sx={{ fontSize: "18px" }} />
              </Box>
                : "" : ""}
              <Typography
                variant="subtitle2"
                align="left"
                sx={{ maxWidth: "580px" }}
                id="title_input"
              >
                {view_post_data.title}
              </Typography>
            </Box>
            {/* Description  */}
            <Box sx={{ position: "relative", my: 4 }}>
              {view_post_data.body ? view_post_data.body.length > 0 ?
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    bgcolor: is_copied == "body" ? "#4aad4a" : "primary.main",
                    color: "White",
                    boxShadow: 4,
                    border: "5px solid white",
                    p: 1,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.navigator.clipboard.writeText(view_post_data.body);
                    set_is_copied("body");
                    setTimeout(() => {
                      set_is_copied(false);
                    }, 1000);
                  }}
                >
                  <ContentCopyIcon sx={{ fontSize: "18px" }} />
                </Box>
                : "" : ""}
              <Typography
                variant="subtitle2"
                align="left"
                sx={{ maxWidth: "580px", whiteSpace: "pre-line" }}
              >
                {view_post_data.body}
              </Typography>
            </Box>
            <br />
            {view_post_data.image || view_post_data.video ? (
              <Box
                sx={{
                  mt: 4,
                  position: "relative",
                  maxWidth: "615px",
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box sx={{ opacity: 1 }}>
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      opacity: 1,
                      zIndex: 12001,
                      bgcolor:
                        is_copied == "image" ? "#4aad4a" : "primary.main",
                      color: "White",
                      boxShadow: 4,
                      border: "5px solid white",
                      p: 1,
                      cursor: "pointer",
                    }}
                    onClick={async () => {
                      if (is_copied != "image")
                        if (image_or_video == "image") {
                          download_image(view_post_data.image,
                            view_post_data.image_title
                          );
                        } else {
                          download_image(view_post_data.video,
                            view_post_data.image_title
                          );
                        }
                      set_is_copied("image");
                    }}
                  >
                    {is_copied == "image" ? (
                      <CircularProgress sx={{ fontSize: "21px" }} />
                    ) : (
                      <DownloadIcon sx={{ fontSize: "21px" }} />
                    )}
                  </Box>
                  {image_or_video == "image" ? (
                    <img

                      src={view_post_data.image}
                      style={{
                        position: "absolute",
                        zIndex: 100,
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%,-50%)",
                        width: "120%",
                        height: "120%",
                        filter: "blur(51.2px)",
                        opacity: "0.7",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Box>
                <Box
                  sx={{
                    position: "relative",
                    maxWidth: "450px",
                    maxHeight: "100%",
                    overflow: "hidden",
                    zIndex: 12000,
                  }}
                >
                  {image_or_video == "video" ? (
                    <>
                      <video

                        autoPlay={false}

                        preload="none"
                        poster={
                          "https://marketing.100percentfranchise.com/media/click_to_load_the_video.png"
                        }
                        muted
                        controls
                        onClick={(e) => e.preventDefault()}
                        loading={"lazy"}
                        style={{ width: '100%' }}
                      >
                        <source src={view_post_data.video} />
                        Your browser does not support this video extension. Please download it by right clicking and selecting "Save video" option.

                      </video>
                    </>
                  ) : (<>
                    <img

                      src={view_post_data.image}
                      style={{ width: "100%" }}
                    />
                  </>
                  )}
                </Box>
              </Box>
            ) : (
              ""
            )}
            <br />

            <Divider sx={{ my: 2 }} flexItem variant="middle" />
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "column", md: "row" },
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <Button
                sx={{ flex: 1, width: 1, mt: 2, minHeight: '43px', }}
                variant="outlined"
                color="warning"
                fullWidth
                onClick={() => {
                  set_is_copied(false);
                  navigate(`/calendar?last_clicked_date=${upload_date}`);
                }}
              >
                Back to calendar
              </Button>
              {user ? (
                user.is_superuser ? (
                  <Box sx={{
                    flex: 1, width: 1, display: "flex", flexWrap: "wrap",
                    flexDirection: { xs: "column", sm: "column", md: "row" },
                  }}>
                    <Button
                      sx={{ flex: 1, mt: 2, minHeight: '43px', }}
                      fullWidth
                      variant="contained"
                      onClick={() => {
                        set_is_copied(false);
                        set_to_edit_or_create(view_post_data.id);

                        if (view_post_data.image_file_type != "not valid") {
                          let url = view_post_data.image;
                          if (view_post_data.image_file_type == "video") {
                            url = view_post_data.video;
                          }
                          fetch(url)
                            .then((response) => response.blob())
                            .then((blob) => {
                              const reader = new FileReader();
                              reader.readAsDataURL(blob);
                              set_post_image_prev_loading(true);
                              reader.onload = () => {
                                const dataURL = reader.result;
                                set_post_image_prev(dataURL);
                                set_post_image_prev_loading(false);
                              };
                            });
                          set_post_image(view_post_data.image);
                        }

                        set_post_platform(view_post_data.platform);
                        set_post_title(view_post_data.title_raw);
                        set_post_body(view_post_data.body_raw);

                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      sx={{ flex: 1, mt: 2, minHeight: '43px', }}
                      variant="gray_button"
                      fullWidth
                      onClick={() => {
                        set_is_copied(false);
                        set_to_delete_warning(view_post_data.id);
                      }}
                    >
                      Delete
                    </Button>
                  </Box>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
            </Box>

            <br />
            <br />
            <br />
            <br />
            <br />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(ViewOrCreatePost);
