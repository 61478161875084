import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, CircularProgress, LinearProgress, Tooltip, Typography } from '@mui/material'

import { axios_get_call, axios_post_call } from '../../../components/AxiosCall';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CustomModal from '../../../components/CustomModal';

import CloseIcon from '@mui/icons-material/Close';


const Dashboard = ({ user }) => {

  const [alert, set_alert] = useState(false);
  const [loading, set_loading] = useState(false);
  const [is_facebook_page_id_missing, set_is_facebook_page_id_missing] = useState(false);
  const [is_facebook_connected, set_is_facebook_connected] = useState(false);
  const [all_facebook_pages, set_all_facebook_pages] = useState([]);
  const [page_to_select, set_page_to_select] = useState(null);

  const navigate = useNavigate()

  const connect_facebook = async () => {
    set_loading("facebook");
    let url = '/social-apps-integration/integrate-facebook/';
    let res = await axios_get_call(url, {}, set_loading, set_alert);

    if (res.data.status == 1) {
      set_loading(false);
      window.open(res.data.response);
    } else if (res.data.status == 0) {
      set_alert(res.data.response);
      set_loading(false);
    }
  }

  const get_all_facebook_pages_by_the_user = async () => {
    set_loading("facebook");
    let url = '/social-apps-integration/get-all-facebook-pages-by-the-user/';
    let res = await axios_get_call(url, {}, set_loading, set_alert);

    if (res.data.status == 1) {
      set_loading(false);
      set_all_facebook_pages(res.data.response);
    } else if (res.data.status == 0) {
      set_alert(res.data.response);
      set_loading(false);
    }
  }

  const save_facebook_page_details = async () => {
    set_loading(true);
    let url = '/social-apps-integration/save-facebook-page-details/';
    let page_access_token = "";
    let page_id = -1;

    if (page_to_select != -1) {
      page_access_token = all_facebook_pages[page_to_select].access_token
      page_id = all_facebook_pages[page_to_select].id
    }


    let res = await axios_post_call(url, { 'page_access_token': page_access_token, 'page_id': page_id }, set_loading);

    if (res.data.status == 1) {
      set_loading(false);
      set_is_facebook_page_id_missing(false)
      set_all_facebook_pages(res.data.response);
      set_alert(res.data.response);
    } else if (res.data.status == 0) {
      set_is_facebook_page_id_missing(false)
      set_alert(res.data.response);
      set_loading(false);
    }
  }


  useEffect(() => {
    if (!(localStorage.getItem('access'))) {
      navigate('/login')
    }
  }, [])

  useEffect(() => {
    if (user) {
      if (user.facebook) {
        if (!user.facebook_page_id) {
          set_is_facebook_page_id_missing(true)
        }
        set_is_facebook_connected(true)
        if (!user.facebook_page) {
          get_all_facebook_pages_by_the_user()
        }
      }
    }
  }, [user])

  return (
    user ?
      <Box>
        {alert ? (
          <Alert
            onClose={() => {
              set_alert(false);
            }}
            severity={alert.toLowerCase().includes("[error]") ? "error" : "success"}
            sx={{
              p: 2,
              zIndex: 9923213,
            }}
          >
            {alert}
          </Alert>
        ) : (
          ""
        )}

        {is_facebook_page_id_missing ?
          <CustomModal set_preview={set_is_facebook_page_id_missing}>
            <Box
              sx={{
                width: { xl: "35%", lg: "50%", md: "60%", sm: "80%", xs: "90%" },
                h: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                bgcolor: "white",
                cursor: "auto",
                borderRadius: 3,
                boxShadow: 24,
                p: 1,

              }}
            >
              <Box sx={{ display: 'flex', width: 1, alignItems: 'right', justifyContent: 'right' }}>
                <Box onClick={() => { set_is_facebook_page_id_missing(false) }} sx={{ cursor: "pointer" }} ><CloseIcon /></Box>
              </Box>
              <Box sx={{
                p: { xl: 4, lg: 4, md: 4, sm: 4, xs: 2 },
                width: "85%",
                height: "100%",
              }}>

                {loading ? (
                  <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
                    <Box><CircularProgress /></Box>
                  </Box>
                ) : (
                  <Box>
                    <Typography  variant='h1'>Select the page</Typography>
                    <Typography mb={4} variant='h3'>Selecting the page that is connected with Instagram will automatically integrate instagram support as well</Typography>

                    <Box>
                      {all_facebook_pages.map((page, index) => {
                        return (
                          <Box id={page.id} onClick={() => { set_page_to_select(index) }} sx={{
                            border: "1px solid",
                            borderColor: index == page_to_select ? "secondary.main" : "white",
                            padding: 3,
                            my: 1,
                            textAlign: "left",
                            cursor: "pointer",
                            "&:hover": {
                              borderColor: "secondary.dark",
                            }
                          }}>
                            <Typography variant='h3'>{page.name}</Typography>
                            <Typography variant='subtitle2'>{page.category}</Typography>
                          </Box>
                        )
                      })}
                      <Box onClick={() => { set_page_to_select(-1) }} sx={{
                        border: "1px solid",
                        borderColor: page_to_select == -1 ? "secondary.main" : "white",
                        padding: 3,
                        my: 1,
                        textAlign: "left",
                        cursor: "pointer",
                        "&:hover": {
                          borderColor: "secondary.dark",
                        }
                      }}>
                        <Typography variant='h3'>None, Use main profile</Typography>
                      </Box>
                      <Button fullWidth variant='contained' disabled={typeof page_to_select != 'number'} onClick={() => {
                        save_facebook_page_details()
                      }}>Select</Button>
                    </Box>
                  </Box>
                )}
                {alert ? (
                  <Alert
                    onClose={() => {
                      set_alert(false);
                    }}
                    severity={alert.toLowerCase().includes("[error]") ? "error" : "success"}
                    sx={{
                      p: 2,
                      zIndex: 9923213,
                    }}
                  >
                    {alert}
                  </Alert>
                ) : (
                  ""
                )}
              </Box>
            </Box>
          </CustomModal>
          : ""}
        <Box sx={user.under_review && !(user.is_superuser) ? { display: "flex", minHeight: "92vh", flexDirection: "column", alignItems: "center", justifyContent: "center" } : { transition: "all 0.25s ease" }}>
          {user.under_review && !(user.is_superuser) ?
            <Typography variant="subtitle2">
              By default your account is under review. After you get approval you will be able to access the website. Please check after some time.
            </Typography>
            :

            <Box>
              <Typography variant="h1" mt={4}>
                Welcome {user ? user.name : ""}!
              </Typography>
              <Box mb={4} mt={4} sx={{ display: 'flex', justifyContent: "center", textAlign: "center", }}>
                <Box sx={{ m: 2 }}>
                  <Box onClick={() => {
                    navigate("/profile")
                  }} sx={{
                    bgcolor: "white", boxShadow: "0px 0px 15px -5px rgba(0,0,0,0.2)", borderRadius: 5, padding: 4, "&:hover": {
                      boxShadow: "0px 0px 15px 0px rgba(0,0,0,0.2)",
                      cursor: "pointer"
                    }
                  }}>
                    <Typography variant='h3'>Manage Profile</Typography>
                  </Box>


                </Box>
                <Box sx={{ m: 2 }}>
                  <Tooltip title={!is_facebook_connected || is_facebook_connected != "reconnect" ? "Double click to reconnect with Facebook & Instagram" : ""} placement="top">
                    <Box onDoubleClick={() => { set_is_facebook_connected("reconnect") }} onClick={() => {
                      if (!is_facebook_connected || is_facebook_connected == "reconnect")
                        connect_facebook()
                    }} sx={{
                      opacity: is_facebook_connected ? is_facebook_connected == "reconnect" ? 1 : 0.5 : 1,
                      bgcolor: "white", boxShadow: "0px 0px 15px -5px rgba(0,0,0,0.2)", borderRadius: 5, padding: 4, "&:hover": {
                        boxShadow: "0px 0px 15px 0px rgba(0,0,0,0.2)",
                        cursor: !is_facebook_connected || is_facebook_connected == "reconnect" ? "pointer" : ""
                      }
                    }}>
                      <Typography variant='h3'>{is_facebook_connected ? is_facebook_connected == "reconnect" ? "Reconnect Facebook & Instagram" : "Facebook & Instagram Connected" : "Connect Facebook & Instagram"}</Typography>
                      {loading == "facebook" ? <LinearProgress color="secondary" /> : ""}
                    </Box>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
          }
        </Box>
      </Box>
      : ""
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {})(Dashboard);;