import { getRadioUtilityClass } from "@mui/material";
import {
  createTheme,
  adaptV4Theme,
  responsiveFontSizes,
} from "@mui/material/styles";

export let theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#042b48",
    },
    secondary: {
      main: "#c2e2d9",
    },
    contrast: {
      main: "#f9b031",
    },
    customBox: {
      default: "#f7f7f7",
      themeAdaptive: "#202020",
    },
    blackAndWhite: "#000000",
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'gray_button' },
          style: {
            margin: "0px",
            fontFamily: "avenir roman,sans-serif",
            lineHeight: "1.5",
            height: '43px',
            letterSpacing: "0.00938em",
            boxShadow: "none",
            transition: "0.1s ",
            textTransform: "none",
            border:"1px solid rgba(34,34,34,0.1)",
            color: "#717171",
            fontWeight: 500,
            fontSize: "14px",

            pl: 4,
            pr: 4,
            "&:hover": { backgroundColor: "rgba(34,34,34,0.1)", boxShadow: "none" },
          },
        },

      ],


      styleOverrides: {

        root: ({ ownerState }) => ({
          borderRadius: 24,
          py: 1,
          whiteSpace:"nowrap",
          minWidth:"100px",
          
          px: 4,
          height: '43px',
          ...(ownerState.variant === 'contained' && ownerState.color === 'secondary' && {
            boxShadow: "none",
            transition: "0.1s ",
            fontWeight: 600,
            fontSize: "16px",
            textTransform: "none",
            "&:hover": { boxShadow: "none" },
          }),
        }),
      },
    },
  },

  typography: {
    cursor:"auto",
    fontFamily: [
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      "sans-serif",
      "Circular Bold",
      "sans-serif",
    ].join(","),
    fontSize: 14,
    color: "#222",
    button: {
      textTransform: "none",
      color: "white",
      fontWeight: "bold"
    },
  },
});

theme = responsiveFontSizes(theme);
theme.typography.h1 = {
  fontSize: "30px",
  fontWeight: 600,
  textAlign: "center",
  fontFamily: "Avenir Heavy,sans-serif",
};
theme.typography.h2 = {
  fontSize: "24px",
  fontWeight: 600,
  textAlign: "center",
  fontFamily: "Avenir Heavy,sans-serif",
};

theme.typography.h3 = {
  fontSize: "20px",
  fontWeight: "bold",
  lineHeight: "25px",
  fontFamily: "Avenir Heavy,sans-serif",
};

theme.typography.subtitle1 = {
  fontSize: "small",
  fontWeight: 300,
  lineHeight: "25px",

  color: "gray",
  fontFamily: "avenir heavy,sans-serif",
};

theme.typography.subtitle2 = {
  margin: "0px",
  fontFamily: "avenir roman,sans-serif",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "1.5",
  letterSpacing: "0.00938em",
  color: "gray",
};


