import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, Redirect, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../actions/auth";
import MenuIcon from "@mui/icons-material/Menu";
import {
	AppBar,
	Box,
	Toolbar,
	IconButton,
	LinearProgress,
	Typography,
	InputBase,
	Menu,
	Badge,
	MenuItem,
	Switch,
	Divider,
	Button,
} from "@mui/material";

import SocialMediaLinks from "./menu_items/SocialMediaLinks";
import EventLinks from "./menu_items/EventLinks";
import ResourceLinks from "./menu_items/ResourceLinks";
import OrderFormLinks from "./menu_items/OrderFormLinks";
import HelpLinks from "./menu_items/HelpLinks";

import site_logo_dark from "../static/media/site logo dark.png";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MoreIcon from "@mui/icons-material/MoreVert";

const Navbar = ({ logout, state, user, setDarkMode, darkMode }) => {
	// const store = useStore();
	const navigate = useNavigate();
	const [title, setTitle] = useState("");
	const [is_login_page, set_is_login_page] = useState(false);
	const [is_settings_page, set_is_settings_page] = useState(false);
	const [light_color, set_light_color] = useState(true);

	const location = useLocation();

	const logout_user = () => {
		logout();
		navigate("/login")
	};

	useEffect(() => {
		if (window.location.href.includes("/calendar"))
			set_light_color(false);
		else if (window.location.href.includes("/dashboard"))
			set_light_color(false);
		else if (window.location.href.includes("/complete-profile"))
			set_light_color(false);
		else if (window.location.href.includes("/profile"))
			set_light_color(false);
		else if (window.location.href.includes("/post"))
			set_light_color(false);
		else
			set_light_color(true);
		if (window.location.href.includes("/login")) {
			set_light_color(true);
			set_is_login_page(true);
		} else {
			set_is_login_page(false);
		}

		if (window.location.href.includes("/calendar") || window.location.href.includes("/form") || window.location.href.includes("/view-leads") || window.location.href.includes("/raffle") || location.pathname == "/") {
			set_light_color(false);
			set_is_settings_page(true);
		} else {
			set_is_settings_page(false);
		}
	}, [location]);

	const guestLinks = () => (
		<Fragment>

			<Link to="/login" style={{ textDecoration: "none" }}>
				<MenuItem
					sx={{ color: "black", fontWeight: 500 }}
					onClick={handleMenuClose}
				>
					Login
				</MenuItem>
			</Link>
			<Link to="/signup" style={{ textDecoration: "none" }}>
				<MenuItem
					sx={{ color: "black", fontWeight: 500 }}
					onClick={handleMenuClose}
				>
					Register
				</MenuItem>
			</Link>
		</Fragment>
	);

	const authLinks = () => (
		<Fragment style={{ margin: 0, padding: 0 }}>

<Box sx={{display: { xs: "initial", sm: "initial", md: "none" }}}>
			<Link
				to={"/"}
				style={{ textDecoration: "none" }}
			>
				<MenuItem
					sx={{ color: "black", fontWeight: 500 }}
					onClick={handleMenuClose}
				>
					Home
				</MenuItem>
			</Link>
			</Box>
			<Link
				to={user ? "/dashboard" : "/login"}
				style={{ textDecoration: "none" }}
			>
				<MenuItem
					sx={{ color: "black", fontWeight: 500 }}
					onClick={handleMenuClose}
				>
					Dashboard
				</MenuItem>
			</Link>
			{/* All links   */}
			<Box sx={{ display: { xs: "initial", sm: "initial", md: "none" } }}>
				<Divider />
				<Typography variant="subtitle2" align="center" sx={{ p: 1, bgcolor: "#f9f9f9" }}>
					Social Media
				</Typography>
				<Link to="/calendar" style={{ textDecoration: "none" }}>
					<MenuItem
						sx={{ color: "black", fontWeight: 500 }}
						onClick={handleMenuClose}
					>
						Calendar
					</MenuItem>
				</Link>
				<Link to="#" style={{ textDecoration: "none" }}>
					<MenuItem
						sx={{ color: "black", fontWeight: 500 }}
						onClick={handleMenuClose}
					>
						Social Media Guide (Coming Soon)
					</MenuItem>
				</Link>
				<Link to="#" style={{ textDecoration: "none" }}>
					<MenuItem
						sx={{ color: "black", fontWeight: 500 }}
						onClick={handleMenuClose}
					>
						Monthly Marketing (Coming Soon)
					</MenuItem>
				</Link>

				<Divider />
				<Typography variant="subtitle2" align="center" sx={{ p: 1, bgcolor: "#f9f9f9" }}>
					Events
				</Typography>
				<Link to={user ? user.form_id ? `/form/${user.form_id}` : "/login" : "/login"} style={{ textDecoration: "none" }}>
					<MenuItem

						sx={{ color: "black", fontWeight: 500 }}
						onClick={handleMenuClose}
					>
						Create Event Forms
					</MenuItem>
				</Link>
				<Link to={user ? "/view-leads" : "/login"} style={{ textDecoration: "none" }}>
					<MenuItem

						sx={{ color: "black", fontWeight: 500 }}
						onClick={handleMenuClose}
					>
						View Form Submissions
					</MenuItem>
				</Link>
				<Link to={user ? "/raffle" : "/login"} style={{ textDecoration: "none" }}>
					<MenuItem

						sx={{ color: "black", fontWeight: 500 }}
						onClick={handleMenuClose}
					>
						Pick a Winner
					</MenuItem>
				</Link>
				<Divider />
				<Typography variant="subtitle2" align="center" sx={{ p: 1, bgcolor: "#f9f9f9" }}>
					Resources
				</Typography>
				<Link to={"#"} style={{ textDecoration: "none" }}>
					<MenuItem

						sx={{ color: "black", fontWeight: 500 }}
						onClick={handleMenuClose}
					>
						Brand Kit (Coming Soon)
					</MenuItem>
				</Link>
				<Link to={"#"} style={{ textDecoration: "none" }}>
					<MenuItem

						sx={{ color: "black", fontWeight: 500 }}
						onClick={handleMenuClose}
					>
						Approved Post Library (Coming Soon)
					</MenuItem>
				</Link>


				<Divider />
				<Typography variant="subtitle2" align="center" sx={{ p: 1, bgcolor: "#f9f9f9" }}>
					Order Forms
				</Typography>
				<Link to={"#"} style={{ textDecoration: "none" }}>
					<MenuItem

						sx={{ color: "black", fontWeight: 500 }}
						onClick={handleMenuClose}
					>
						Monthly Banner (Coming Soon)
					</MenuItem>
				</Link>
				<Divider />
				<Typography variant="subtitle2" align="center" sx={{ p: 1, bgcolor: "#f9f9f9" }}>
					Help
				</Typography>
				<Link to={"#"} style={{ textDecoration: "none" }}>
					<MenuItem

						sx={{ color: "black", fontWeight: 500 }}
						onClick={handleMenuClose}
					>
						Tutorials (Coming Soon)
					</MenuItem>
				</Link>
				<Link to={"#"} style={{ textDecoration: "none" }}>
					<MenuItem

						sx={{ color: "black", fontWeight: 500 }}
						onClick={handleMenuClose}
					>
						Support (Coming Soon)
					</MenuItem>
				</Link>
				<Divider />
				<Typography variant="subtitle2" align="center" sx={{ p: 1, bgcolor: "#f9f9f9" }}>
					Other
				</Typography>


			</Box>
			{
				user ? user.is_superuser ?
					<>
						<a
							href={`${process.env.REACT_APP_BACKEND_URL.split("/api")[0]}/admin/`}
							style={{ textDecoration: "none" }}
							target="_blank"
						>
							<MenuItem

								sx={{ color: "black", fontWeight: 500 }}
							>
								Admin panel
							</MenuItem>
						</a>
						<Divider />

					</> : "" : ""
			}
			<Divider />
			<Link
				to={user ? "/calendar" : ""}
				style={{ textDecoration: "none" }}
			>
				<MenuItem
					sx={{ color: "black", fontWeight: 500 }}
					onClick={() => { logout_user(); handleMenuClose(); navigate("/login") }}
				>
					Logout
				</MenuItem>
			</Link>
		</Fragment >
	);

	const [anchorEl, setAnchorEl] = React.useState(null);

	const isMenuOpen = Boolean(anchorEl);

	const handleProfileMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const menuId = "primary-search-account-menu";
	const renderMenu = (
		<Menu
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			id={menuId}
			keepMounted
			transformOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			open={isMenuOpen}
			onClose={handleMenuClose}
			sx={{ mt: "48px", color: "#F9F9F9" }}
		>
			{state.auth.isAuthenticated ? authLinks() : guestLinks()}

			<Divider />
			<Box
				sx={{
					p: 2,
				}}
			>
				{state.auth.user ? (
					<Typography sx={{ color: "lightgrey" }}>
						{" "}
						{state.auth.user.email}{" "}
					</Typography>
				) : (
					""
				)}
			</Box>
		</Menu>
	);

	const label = { inputProps: { "aria-label": "Light Mode" } };
	return (
		// <Box sx={{ borderRadius:0, flexGrow: 1, bgcolor: light_color ? "white" : "primary.main" }}>
		<Box sx={{ borderRadius: 0, flexGrow: 1, bgcolor: "primary.main"}}>
			
			<Toolbar
				sx={{
					display: "flex",
					justifyContent: "left",
					height: "75px",
					zIndex: 1,
					borderBottom: "1px solid lightgrey",
					mb: 2,
				}}
			>
				{/* Logo part  */}
				<Link
					to={user ? "/calendar" : ""}
					style={{ textDecoration: "none" }}
				>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							height: "55px",

							margin: 1,
						}}
					>
						{light_color ?
							<Typography variant="h1" sx={{
								color: "white",
								fontSize: { xs: "1em", sm: "1.5em", md: "2em" }
							}} align="center">
								100% Marketing Platform
							</Typography>
							:
							<img
								src={site_logo_dark}
								height="100%"
								style={{ marginRight: "7px" }}
								loading="lazy"
							/>
						}
					</Box>
				</Link>

				<Box sx={{
					flex: 3, display: {
						md: state.auth.isAuthenticated == true ? "flex" : "none",
						sm: "none",
						xs: "none",

					}, justifyContent: "left",
					borderLeft: "1px solid white",

				}}>
					<Box sx={{ m: 1 }}>
						<Typography
							onClick={() => { navigate("/") }}
							sx={{ color: "white", p: 1, borderRadius: 12, px: 2, cursor: "pointer", fontWeight: "600", ":hover": { bgcolor: "rgba(225,225,225,0.1)" }, whiteSpace: "nowrap" }}
						>
							Home
						</Typography>
					</Box>
					<Box sx={{ m: 1 }}>
						<SocialMediaLinks />
					</Box>
					<Box sx={{ m: 1 }}>
						<EventLinks />
					</Box>
					<Box sx={{ m: 1 }}>
						<ResourceLinks />
					</Box>
					<Box sx={{ m: 1 }}>
						<OrderFormLinks />
					</Box>
					<Box sx={{ m: 1 }}>
						<HelpLinks />
					</Box>
				</Box>
				<Box sx={{ flex: 3, display: "flex", justifyContent: "right", }}>
					<Box
						sx={{
							display: {
								md: state.auth.isAuthenticated == false ? "flex" : "none",
								sm: "none",
								xs: "none",
							},
						}}
					>
						<Button
							onClick={() => navigate("/login")}
							sx={{
								fontWeight: 600,
								fontSize: "16px",
								borderRadius: 21, px: 3,
								color: "white"
							}}
						>
							Log in
						</Button>
					</Box>
					<Box
						sx={{
							display: {
								md: state.auth.isAuthenticated == false ? "flex" : "none",
								xs: "none",
							},
						}}
					>
						<Button
							variant="contained"
							color="secondary"
							onClick={() => navigate("/signup")}
						>
							Sign up
						</Button>
					</Box>
				</Box>

				<Box sx={{
					display: {
						md: state.auth.isAuthenticated == false ? "none" : "initial",
						xs: "initial",
					},
				}}>
					{/* Profile Part  */}
					<IconButton
						edge="end"
						aria-label="account of current user"
						aria-controls={menuId}
						aria-haspopup="true"
						onClick={handleProfileMenuOpen}
						color="inherit"

					>
						{state.auth.isAuthenticated ? (
							user ? (
								user.profile_picture ? (
									<Box
										sx={{
											display: "flex",
											justifyContent: "right",
											alignItems: "center",
											width: "100%",
										}}
									>
										<Box sx={{ flex: 1 }}>
											<MenuIcon />
										</Box>
										<Box sx={{ flex: 1 }}>
											<img
												width={30}
												height={30}
												style={{ borderRadius: 30 }}
												src={`${user.profile_picture}`}
											/>
										</Box>
									</Box>
								) : (
									<AccountCircle sx={{ width: 35, height: 35, color: "white" }} />
								)
							) : (
								<AccountCircle sx={{ width: 35, height: 35, color: "white" }} />
							)
						) : (
							<MoreIcon sx={{color:"white"}} />
						)}
					</IconButton>
				</Box>
			</Toolbar>

			{renderMenu}

		</Box>
	);
};
// [End] main function to render navbar
const mapStateToProps = (state) => ({
	state: state,
	user: state.auth.user,
});

export default connect(mapStateToProps, { logout })(Navbar);
